import React, { useState, useEffect, useRef } from "react";
import "./style.css";

interface IPropsType {
    userName: string,
    mitraTheme: string
}

/**
   @function SaleOrigPaintings - функциональный компонент, представляющий блок формы для отправки заявки на заказ картины. 
   Позволяет заполнить данные, отправить заявку и отображает блоки с уведомлениями об отправке данных.
*/
function SaleOrigPaintings(props: IPropsType) {

    //Переменные, которые требуется отслеживать с помощью хуков состояния
    const [fullName, setFullName] = useState('');
    const [PhoneNum, setPhoneNum] = useState('');
    const [email, setEmailName] = useState('');
    const [discr, setDiscrName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isOpenDowBlock, setIsOpenDowBlock] = useState(false);
    const [isOpenGoodSendBlock, setIsOpenGoodSendBlock] = useState(false);
    const [isOpenBadSendBlock, setIsOpenBadSendBlock] = useState(false);
    const [errorbool, setErrorbool] = useState(false);
    const [resp, setResp] = useState(false);
    const [isCustomCheckbox, setIsCustomCheckbox] = useState(false);
    const [trt, trts] = useState(true);
    function changeCustomCheckbox() {
        if (isCustomCheckbox) {
            setIsCustomCheckbox(false);
        } else {
            setIsCustomCheckbox(true);
        }
    }

    const chatId = -1002061148970; // 797596124 - Kirill, 1448729978 - Evgeni, 2122883046 - Valeri, -1002061148970 - chat

    //Функция открытия формы для заполнения имени(возможно автоматически) и номера телефона(вручную)
    function openForm() {
        setIsFormOpen(true);

        if (props.userName !== '') //Проверка возможности вставить имя автоматически
        {
            setFullName(props.userName);
        }

        console.log(fullName);
    }

    //Функция закрытия формы, описанной выше
    function closeForm() {
        setIsFormOpen(false);
    }

    //Функция открытия формы с отправкой данных, чтобы запрос успел обработаться и вернуть результат отправки(успешный/провальный)
    function openDowBlock() {
        setIsOpenDowBlock(true);
    }

    //Функция закрытия формы, описанной выше
    function closeDowBlock() {
        setIsOpenDowBlock(false);
    }

    //Функция открытия формы с проваленной отправкой данных
    function openBadSendBlock() {
        setIsOpenBadSendBlock(true);
    }

    //Функция закрытия формы, описанной выше
    function closeBadSendBlock() {
        setIsOpenBadSendBlock(false);
    }

    //Функция открытия формы с проваленной отправкой данных
    function openGoodSendBlock() {
        setIsOpenGoodSendBlock(true);
    }

    //Функция закрытия формы, описанной выше
    function closeGoodSendBlock() {
        setIsOpenGoodSendBlock(false);
    }

    //Функции проверки полей ввода в форме(ФИО/Телефон). Работает так, что пока поля не заполнены, кнопка будет неактивна и подсвечиваться серым цветом, а при вводе хотя бы
    //каких-то данных в оба поля кнопка будет активироваться и подсвечиваться градиентом
    const handleFullNameChange = (event) => {
        const newFullName = event.target.value;
        setFullName(newFullName);
        updateButtonState(newFullName, PhoneNum, email, discr);
    }

    const handlePhoneNumChange = (event) => {
        const newPhoneNum = event.target.value;
        setPhoneNum(newPhoneNum);
        updateButtonState(fullName, newPhoneNum, email, discr);
    }

    const handleDiscrChange = (event) => {
        const newDiscr = event.target.value;
        setDiscrName(newDiscr);
        updateButtonState(fullName, PhoneNum, email, newDiscr);
    }
    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmailName(newEmail);
        updateButtonState(fullName, PhoneNum, newEmail, discr);
    }

    const updateButtonState = (newFullName, newPhoneNum, newEmail, newDiscr) => {
        if (newFullName.trim() !== "" && newPhoneNum.trim() !== "" && newEmail.trim() !== "" && newDiscr.trim() !== "") {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }

    //Функция отправки изначальной формы, активирующая цепочку открытия соответствующих форм. Изначально будет идти форма отправки данных, пока запрос обрабатывается.
    //После чего уже откроется форма в соответствии с ответом запроса. Если всё успешно, то на chatId в Телеграм отправляется соответствующая строка message.
    const submitForm = (e) => {
        console.log('submit');
        e.preventDefault();
        var message = `URL: ${document.URL}\n\n ФИО: ${fullName}\n Номер телефона: ${PhoneNum}\n Email: ${email}\n\n Описание предзаказа: ${discr}\n`;
        sendMessage(chatId, message, errorbool);
        closeForm();
        openDowBlock();

        setTimeout(() => {
            closeDowBlock();

            if (errorbool) {
                openBadSendBlock();
            }
            else {
                openGoodSendBlock();
            }
        }, 3000);
    }

    //Функция отправки запроса, содержащего данные из формы и ссылку на страницу, откуда отправлен запрос, на телеграм с определенным chatId. Осуществляется запрос через fetch
    const sendMessage = (chatId, text, errorbool) => {
        const url = 'https://api.telegram.org/bot7064703080:AAGVqAkyT6-Gy1Y-dMEf0XPM23Ci1lkvx88/sendMessage';
        const params =
        {
            chat_id: chatId,
            text: text
        };

        fetch(url,
            {
                method: 'POST', //Запрос через метод POST для шифрования данных
                headers:
                {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            })
            .then(response => response.json())
            .then(data => {
                setResp(data);
            })
            .catch(error => {
                setErrorbool(true);
            });
    };

    useEffect(() => {
        const urlD = window.location.href;
        if (urlD.split('#').pop() == 'fromMain') {
            setIsFormOpen(true);
        }
    }, []);

    return (
        <React.Fragment>
            <div className="preOrderWrapper">
                <div className="preOrder">
                    <div className="preOrderTitle">
                        <h4>Предзаказ!</h4>
                        {props.mitraTheme === "MitraArt" ? (
                            <p>Подберём для вас предмет искусства, учитывая все ваши предпочтения</p>
                        ): (
                            <p>Подберём для вас персонализированный портфель активов, учитывая все ваши предпочтения</p>
                        )}
                    </div>
                    <span className="preOrderButton" onClick={openForm}>Оставить заявку</span>
                </div>
            </div>



            <div className="container-login100" id="container-login100">
                <div id="form" className="wrap-login100" style={{ display: isFormOpen ? 'block' : 'none' }}>
                    <button className="closeBtn" type="button" id="close" onClick={closeForm}>
                        <svg width="15" height="15" style={{ padding: '1px', background: '#fff' }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                        </svg>
                    </button>
                    <form className="login100-form validate-form p-l-55 p-r-55 p-t-178">
                        <div className="login100-formTitle">
                            <h4>Оставьте заявку</h4>
                            <div className="stroke"></div>
                            {props.mitraTheme === "MitraArt" ? (
                                <p>Мы&nbsp;подберём для вас предмет искусства, учитывая все ваши предпочтения</p>
                            ) : (
                                <p>Мы&nbsp;подберём для вас персонализированный портфель активов, учитывая все ваши предпочтения по&nbsp;виду имущества, локации и&nbsp;бюджету</p>
                            )}
                            
                        </div>
                        <div className="login100-formWrapper">
                            <div className="wrap-input100 validate-input m-b-16">
                                <h5>ФИО*</h5>
                                <input className="input100" type="text" id="name" placeholder="Введите ФИО" value={fullName} onChange={handleFullNameChange} />
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input">
                                <h5>Номер телефона*</h5>
                                <input className="input100" type="tel" id="phone" placeholder="Телефон" value={PhoneNum} onChange={handlePhoneNumChange} />
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input m-b-16">
                                <h5>Ваша почта*</h5>
                                <input className="input100" type="text" id="email" placeholder="Почта" value={email} onChange={handleEmailChange} />
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input m-b-16" style={{ marginTop: '15px    ' }}>
                                <h5>Комментарий</h5>
                                <textarea className="input100" type="text" id="discr" placeholder="Введите комментарий" value={discr} onChange={handleDiscrChange} />
                                <span className="focus-input100"></span>
                            </div>

                            <div className="login100-customInputCheckbox validate-input">
                                <input className="hiddenDefaultCheckbox" id="hiddenDefaultCheckBox" type="checkbox"></input>
                                <div className={isCustomCheckbox ? `customCheckboxContainerActive` : `customCheckboxContainer`} onClick={changeCustomCheckbox}></div>
                                <p>Я&nbsp;прочитал(а) и&nbsp;согласен(на) с&nbsp;Политикой обработки персональных данных</p>
                            </div>
                        </div>

                        <div className="container-login100-form-btn">
                            <button className="sendBut login100-form-btn" style={{ background: isButtonDisabled ? '#B7B6BB' : 'linear-gradient(229.99deg, #BA38FF -2.16%, #AF2EFF 16.16%, #9E00FF 51.38%, #5532FF 71.26%, #5431FF 79.66%, #5430FF 93.81%)' }} id="submit" onClick={submitForm} disabled={isButtonDisabled}>Отправить данные</button>
                        </div>
                    </form>
                </div>

                <div id="OpenDowBlock" className="wrap-login100w" style={{ display: isOpenDowBlock ? 'block' : 'none' }}>
                    <form className="login100-formw validate-form p-l-55 p-r-55 p-t-178">
                        <span className="login100-form-titlew">
                            <button className="closeBtnw" type="button" id="close" onClick={closeDowBlock}>
                                <img style={{ padding: '1px', background: '#fff' }} src="../../../Content/MithraThemeV2/img/close.svg" />
                            </button>
                        </span>
                        <span className="formHeaderw">Данные обрабатываются ...</span>
                        <section className="dots-container">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </section>
                    </form>
                </div>

                <div id="BadSendBlock" className="wrap-login100w_r" style={{ display: isOpenBadSendBlock ? 'block' : 'none' }}>
                    <form className="login100-formw_r validate-form p-l-55 p-r-55 p-t-178">
                        <span className="login100-form-titlew_r">
                            <button className="closeBtnw_r" type="button" id="close" onClick={closeBadSendBlock}><img style={{ padding: '1px', background: '#fff' }} src="../../../Content/MithraThemeV2/img/close.svg" /></button>
                        </span>
                        <div className="ErrorBlock">
                            <span className="formHeaderw_r">Ошибка!</span>
                            <span className="dir_err_r">Что-то пошло не так, попробуйте отправить заявку еще раз!</span>
                        </div>
                    </form>
                </div>

                <div id="GoodSendBlock" className="wrap-login100w_g" style={{ display: isOpenGoodSendBlock ? 'block' : 'none' }}>
                    <form className="login100-formw_g validate-form p-l-55 p-r-55 p-t-178">
                        <span className="login100-form-titlew_g">
                            <button className="closeBtnw_g" type="button" id="close" onClick={closeGoodSendBlock}><img style={{ padding: '1px', background: '#fff' }} src="../../../Content/MithraThemeV2/img/close.svg" /></button>
                        </span>
                        <div className="GoodBlock">
                            <span className="formHeaderw_g">Данные успешно отправлены</span>
                            <span className="dir_good">Ваша заявка на помощь агента успешно отправлена. В ближайшее время мы с вами свяжемся!</span>
                        </div>
                        <div className="container-login100-form-btn_g">
                            <button className="sendBut_g login100-form-btn_g" onClick={closeGoodSendBlock} type="button">Ок</button>
                        </div>
                    </form>
                </div>
            </div>

        </React.Fragment>

    );
}
export default SaleOrigPaintings;