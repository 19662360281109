import { Nullable, ISelectListItem, IAddressModel, IPictureModel } from "../Common/types";

export class CardLotModel {
    LotId: number;
    StatusId: number;
    ImageId: number;
    TradeTypeId: number;
    LotName: string;
    StatusName: string;
    ImageName: string;
    ImagePath: string;
    FullImagePath: string;
    SeoUrl: string;
    ContentType: string;
    AuctionType: string;
    Price: number;
    IsClosedTrade: boolean;
    IsInFavorites: boolean;
}

// данный enum должен соответствовать enum из Mithra.Data.Domain.OrderServices
export enum OrderStatus {
   // [Description("Ожидаем поступления оплаты")]
        NoPaid = 1,
   // [Description("Оплата поступила")]
        Paid = 2,
   // [Description("В процессе возврата")]
        NeedReturnDeposit = 3,
   // [Description("Возврат осуществлён")]
        DepositReturned = 4
}