import React, { MouseEventHandler, useState, useEffect } from "react";
import $ from "jquery";
import { DEFAULT_LOT_IMAGE, LOT_DETAILS_URL } from "../settings";
import { Nullable, IListLot, DocumentTypes } from "../Common/types";
import CloseToggleLotIsFavorite from "../Common/ToggleLotIsFavorite/CloseToggleLotIsFavorite";
import SCCloseRowItem from "../Common/SCcloseRowItem/SCcloseRowItem";
import AccountInfo from "../Profile/AccountInfo";
import { CardLotModel, OrderStatus } from "./types"

interface IPropsType {
    lot: CardLotModel
}

function CardLotCloseSales({ lot }: IPropsType) {
    let url = lot.SeoUrl ? "/" + lot.SeoUrl : LOT_DETAILS_URL + lot.LotId;
    const [isSignedDocument, setIsSignedDocument] = useState(false);
    const [isServicePaid, setIsServicePaid] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isShowClosePopap, setIsShowClosePopap] = useState(false);
    const [commission, setCommission] = useState('2%');

    if (AccountInfo != null && AccountInfo != undefined) { 
        if (AccountInfo.Services && AccountInfo.Services.length > 0) {
            if (AccountInfo.Services[0].OrderStatusId == OrderStatus.Paid)
                setIsServicePaid(true);
        }

        if (AccountInfo.ListDocuments && AccountInfo.ListDocuments.length > 0) {
            let len = AccountInfo.ListDocuments.length;

            for (var i = 0; i < len; i++) {
                if (AccountInfo.ListDocuments[i].DocumentType == DocumentTypes.Contract)
                    if (AccountInfo.ListDocuments[i].IsSigned)
                        setIsSignedDocument(true);
            }
        }
    }

    const enterToPageLot = () => {
        window.location.href = url;
    };

    // Функция для открытия попапа при нажатии на ссылку
    function openPopup(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault(); // Предотвращаем стандартное действие перехода по ссылке
        setIsPopupOpen(true);
    }

    function closePopup(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        setIsPopupOpen(false);
        console.log("Функция закрытия попапа");
    }

    const handleAuxClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        if (event.button === 1) {
            // Средняя кнопка мыши
            event.preventDefault();
            setIsPopupOpen(true);
        }
    };

    const handleContextMenu = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        event.preventDefault(); // Предотвращаем открытие контекстного меню
        setIsPopupOpen(true);
    };

    return (
        <>
        <div className="cardContent">
            <div className="cardImage">
                <a
                    onClick={openPopup}
                    onAuxClick={handleAuxClick}
                    onContextMenu={handleContextMenu}
                >
                        {lot.ImagePath && lot.ImagePath.length > 0 ? (
                        <img
                                className="img-100w"
                                key={lot.ImageId}
                                src={lot.FullImagePath}
                                alt={lot.ImageName}
                        />
                    ) : (
                        <img
                            className="img-100w"
                            src="../../main-images/defaultLotImage.png"
                            alt="Картинка по умолчанию"
                        />
                    )}
                </a>
                {AccountInfo.Id &&
                        <CloseToggleLotIsFavorite
                        lotId={lot.LotId}
                        curUserFavorite={lot.IsInFavorites}
                        showText={false}
                        type="lot"
                    />
                }
            </div>
            <div className="cardInfo">
                <a
                    className="cardName"
                    href={url}
                    data-attribute-URL_={url}
                    onClick={
                        !isServicePaid || !isSignedDocument ? openPopup : enterToPageLot
                    }
                >
                        {lot.LotName}
                </a>

                {/* Попап */}
                {(!isSignedDocument || !isServicePaid) && isPopupOpen && (

                    <SCCloseRowItem
                        closePopup={closePopup}
                        userName=""
                        URL_={url}
                        openPopup={openPopup}
                        onClose={closePopup}
                        mitraTheme={'MithraArt'}
                        commission={commission}
                    />
                )}

                <div className="cardDescription">
                    <div className="cartType cardDescriptionItem">
                        <h5>Тип торга:</h5>
                            <span>{lot.AuctionType}</span>
                    </div>
                    <div className="cardTypeSale cardDescriptionItem">
                        <h5>Тип продажи:</h5>
                        <span>Закрытый<a href="" className="cl-a-cl-big-down" onClick={(eo) => {
                            eo.preventDefault();
                            setIsShowClosePopap(true);
                        }}><img src="../../../main-images/closedTypeSaleIcon.svg" /></a></span>
                    </div>
                    <div className="cardNumber cardDescriptionItem">
                            <h5>Номер лота:</h5>
                            <span>{lot.LotId}</span>
                    </div>
                    <div className="cardPrice cardDescriptionItem">
                        <h5>Текущая цена:</h5>
                        <span>Скрыта</span>
                    </div>
                </div>
                <a className="showPrice" onClick={openPopup}>Узнать цену</a>
                <div className="cardActivity">
                    <div className="views cardActivityItem">
                        <img src="../../../main-images/views.svg" alt="views"></img>
                        <span>152</span>
                    </div>
                    <div className="viewNow cardActivityItem">
                        <img src="../../../main-images/viewNow.svg" alt="view now"></img>
                        <span>10</span>
                    </div>
                    <div className="applications cardActivityItem">
                        <img src="../../../main-images/applications.svg" alt="applications"></img>
                        <span>6 Заявок</span>
                    </div>
                </div>
            </div>
        </div>

            {
        isShowClosePopap &&
        <div className="closePopap">
            <div className="closeWrap">
                <svg onClick={() => { setIsShowClosePopap(false) }} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                <h2>«ЗАКРЫТЫЕ ПРОДАЖИ»: ЧТО ЭТО? <hr></hr> </h2>
                <p>Закрытые продажи представляют собой бутиковую продажу, при которой информация о&nbsp;лоте открывается пользователю только после заключения NDA</p>
                <span onClick={() => { setIsShowClosePopap(false) }}>Перейти к лотам</span>
            </div>
        </div>
    }
        </>
        )
}

export default CardLotCloseSales;