import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from "react";
import { useSignal, useSlot } from 'react-signal-slot'
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import moment from "moment";

interface IPropsType {
    StartDate: any,
    EndDate: any,
    CalendarLocale: object
}

function FilterDate(props: IPropsType, ref) {

    const [startDate, setStartDate] = useState(!Number.isNaN(props.StartDate) ? new Date(props.StartDate) : null);
    const [endDate, setEndDate] = useState(!Number.isNaN(props.EndDate) ? new Date(props.EndDate) : null);

    const signalChangeDate = useSignal();

    addLocale('ru', props.CalendarLocale);

    const Change = () => {
        var date1 = moment(startDate);
        var date2 = moment(endDate);
        var obj = {
            StartDate: date1.isValid() ? date1.format('YYYY-MM-DDTHH:mm:ss') : NaN,
            EndDate: date2.isValid() ? date2.format('YYYY-MM-DDTHH:mm:ss') : NaN,
        };
        console.log('emit signal filter Date change');
        signalChangeDate('ChangeDate', obj);
    }

    const Reset = () => {
        var obj = {
            StartDate: NaN,
            EndDate: NaN,
        };
        setStartDate(null);
        setEndDate(null);
        console.log('emit signal reset filter Date');
        signalChangeDate('ChangeDate', obj);
    }

    return (
        <>
            <h6 className="sidebar-sub-title pt-3 start-date-title">Дата начала торгов (с)</h6>
            <div className="input-custom input-custom-datepicker input-group date" id="datetimepicker1">
                <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" locale="ru" />
            </div>

            <h6 className="sidebar-sub-title pt-3 end-date-title">Дата окончания торгов (по)</h6>
            <div className="input-custom input-custom-datepicker input-group date" id="datetimepicker2">
                <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" locale="ru" />
            </div>
            <div className="dateButtonsWrap">
                <button className="newLotButton btn btn-sm btn-primary white-space-nowrap px-2 nav-text-btn" type="button" id="close" onClick={Change}>
                    Применить
                </button>
                <button className="newLotButton resetFilter btn btn-sm btn-primary white-space-nowrap px-2 nav-text-btn" type="button" id="close" onClick={Reset}>
                    Сбросить
                </button>
            </div>
        </>
    );
}

export default FilterDate;