import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from "react";
import { useSignal, useSlot } from 'react-signal-slot'

interface IPropsType {
    StartPrice: number,
    EndPrice: number
}

function FilterPrice(props: IPropsType, ref) {

    const [startPrice, setStartPrice] = useState(props.StartPrice);
    const [endPrice, setEndPrice]     = useState(props.EndPrice);
    const [isErrorStartPrice, setErrorStartPrice] = useState(false);
    const [isErrorEndPrice, setErrorEndPrice]     = useState(false);

    const signalChangePrice = useSignal();

    const Change = () => {
        console.log('onClick');
        if (!isErrorStartPrice && !isErrorEndPrice) {
            var obj = {
                StartPrice: startPrice,
                EndPrice: endPrice
            };
            console.log('emit signal');
            signalChangePrice('ChangePrice', obj);
        }
    }

    const Reset = () => {
        var obj = {
            StartPrice: 0,
            EndPrice: 0
        };
        setStartPrice(0);
        setEndPrice(0);
        console.log('emit signal reset filter Price');
        signalChangePrice('ChangePrice', obj);
    }

    const setInputFilter = (value, name) => {
        var flag = /^\d*\.?\d*$/.test(value);
        console.log('setInputFilter: ',value, flag);

        if (name == "startPrice") {
            setStartPrice(value);
            setErrorStartPrice(!flag);
        }
        else {
            setEndPrice(value);
            setErrorEndPrice(!flag);
        }
    }

    return (
        <>
        <h6 className="sidebar-sub-title clear-fx price-title">Цена</h6>
            <div className="row gutter-row-10 mb-2">
                <div className="col-6 gutter-col-10">
                    <div className="input-custom">
                        <input type="text" className={isErrorStartPrice ? "input-error form-control app-form-control white-bg" : "form-control app-form-control white-bg"} placeholder="От" value={startPrice} onChange={(e) => setInputFilter(e.target.value, "startPrice")} />
                    </div>
                </div>
                <div className="col-6 gutter-col-10">
                    <div className="input-custom">
                        <input type="text" className={isErrorEndPrice ? "input-error form-control app-form-control white-bg" : "form-control app-form-control white-bg"} placeholder="До" value={endPrice} onChange={(e) => setInputFilter(e.target.value, "endPrice")} />
                    </div>
                </div>
                {(isErrorStartPrice || isErrorEndPrice) && <p>Допускается ввод только цифр и точки</p>}
                <button className="newLotButton btn btn-sm btn-primary white-space-nowrap px-2 nav-text-btn" type="button" id="close" onClick={Change}>
                    Применить
                </button>
                <button className="newLotButton resetFilter btn btn-sm btn-primary white-space-nowrap px-2 nav-text-btn" type="button" id="close" onClick={Reset}>
                    Сбросить
                </button>
            </div>
        </>
    );
}

export default FilterPrice;