import React, { useState } from "react";
import Fetch from "../Fetch";
import AccountInfo from "../../Profile/AccountInfo";

interface IPropsType {
    lotId: number,
    curUserFavorite: boolean,
    showText: boolean,
    type?: string
}

function closeToggleLotIsFavorite(props: IPropsType) {
    const [curUserFavorite, setCurUserFavorite] = useState<boolean>(props.curUserFavorite);
    const [error, setError] = useState<string>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const toggleFavorite = () => {
        setSaving(true);
        Fetch("/FavoritesApi/ToggleLotIsFavorite?lotId="+props.lotId,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
            .then(res => res.json())
            .then(
                (result) => {
                    setSaving(false);
                    setCurUserFavorite(!curUserFavorite);
                },
                (error) => {
                    setSaving(false);
                    setError("Не удалось сохранить изменения.");
                }
            );
    }
    /*
     * <input type="checkbox" checked={lot.CurUserFavorite} autoComplete="off" disabled={!lot.UserLoggedIn}/>
     */
    return <React.Fragment>
        {props.type == "lot" && <React.Fragment>
            <button onClick={toggleFavorite} className={"btn favorite-btn cl-f-btn" + (curUserFavorite ? " m-icon-hart-fill" : " m-icon-heart")} disabled={AccountInfo.Id == 0 || saving} >
            </button>        </React.Fragment>}
        {props.type == "product" && <React.Fragment>
            {props.showText && <a href="javascript:void(0)" onClick={toggleFavorite} className="ml-2 font-weight-400 pr-2 hidden-md-mobile">{(curUserFavorite ? "Удалить из избранного" : "Добавить в избранное")} </a>}
            <span onClick={toggleFavorite} className={(curUserFavorite ? " m-icon-hart-fill" : " m-icon-heart")}></span>
        </React.Fragment>}
        {error && <span style={{ cursor: 'pointer' }} className="text-danger">{error}</span>}
    </React.Fragment>;
}

export default closeToggleLotIsFavorite;