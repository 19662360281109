/* eslint-disable prettier/prettier */
import React, { useState, useRef } from "react";
import Fetcher from "../../Common/Fetch";
import { IProfileDetailsModel, ICreateOrderServiceModel, ISimpleRegisterModel } from "../../Profile/Details/types";
import { ILotModel, ISelectListItem } from "../../Common/types";
import { DocumentTypes } from "../../Common/types";
import "./SCcloseRowItem.css";
//import "../../../Content/MithraThemeRealty/styles/main.css";
import AccountInfo from "../../Profile/AccountInfo";
import { fetcherPOST, fetcherGET } from "../Fetch";
import LoginWithCert from "../LoginWithCert";
declare var CADESCOMSignData: any;

/**
 * @interface IExtraDataDetails
 * @extends IProfileDetailsModel
 */

interface IExtraDataDetails extends IProfileDetailsModel {
    /**
     * Пароль
     * @type {string}
     */
    Password: string,
    /**
     * Открыт ли какой-либо попап. Необходим в сочетании с другими свойствами, чтобы попап открылся
     * @type {boolean}
     */
    IsSomeOneOpen: boolean,
    /**
     * Открыта ли форма регистрации
     * @type {boolean}
     */
    IsRegisterFormOpen: boolean,
    /**
     * Открыта ли форма с выбором типа пользователя - юрлицо, физлицо, ИП
     * @type {boolean}
     */
    IsZNDAFormOpen: boolean,
    /**
     * Открыт ли стартовый попап, предлагающий зарегистрироваться либо войти
     * @type {boolean}
     */
    IsDefOpen: boolean,
    /**
     * Открыта ли форма аутентификации
     * @type {boolean}
     */
    IsLoginFormOpen: boolean,
    /**
     * Стоит ли галочка в чекбоксе - Женщина
     * @type {boolean}
     */
    IsFemaleChecked: boolean,
    /**
     * Стоит ли галочка в чекбоксе - Мужчина
     * @type {boolean}
     */
    IsMaleChecked: boolean,
    /**
     * Открыт ли попап с поздравлениями с регистрацией
     * @type {boolean}
     */
    IsGoodRegist: boolean,
    /**
     * Открыт ли попап, предлагающий заполнить профиль и подписать NDA
     * @type {boolean}
     */
    IsNdaPopupOpen: boolean,
    /**
     * Открыт ли попап с предложением активировать доступ к закрытым продажам
     * @type {boolean}
     */
    IsNdaPopupOpen2: boolean,
    /**
     * Открыт ли попап с формированием платёжного поручения
     * @type {boolean}
     */
    IsNdaPopupOpen3: boolean,
    /**
     * Открыт ли попап с записью об ожидании оплаты
     * @type {boolean}
     */
    IsNdaPopupOpen4: boolean,
    IsNdaPopupOpen4_1: boolean,
    /**
     * Открыт ли попап с оплатой
     * @type {boolean}
     */
    IsPaymentPopupOpen: boolean,
    /**
     * Поставлена ли галочка согласия с NDA
     * @type {boolean}
     */
    IsNDAsignedUp: boolean,
    /**
     * Подписан ли документ
     * @type {boolean}
     */
    IsSignedNDA: boolean,
    /**
     * Оплачен ли документ
     * @type {boolean}
     */
    IsPaid: boolean,
    /**
     * Подтверждёно ли совершеннолетие
     * @type {boolean}
     */
    IsLegalAge: boolean,
    /**
    * Подписался ли пользователь на новости
    * @type {boolean}
    */
    IsSignedOnNews: boolean,
    /**
     * Тип пользователя - юрлицо, физлицо, ИП
     * @type {string}
     */
    SelectedEntityType: string,
    /**
     * Шаг подписания
     * @type {number}
     */
    Step: number,
    /**
     * username пользователя
     * @type {string}
     */
    User: string,
    /**
     * адрес лота 
     * @type {string}
     */
    URL_: string,
    /**
     * Цифровая подпись
     * @type {string}
     */
    Signature: string,
    /**
     * id оплачиваемого тарифа
     * @type {number}
     */
    ServiceId: number,
    /**
     * Модель для регистрации пользователя
     * @type {ISimpleRegisterModel}
     */
    SimpleRegisterModel: ISimpleRegisterModel,
    /**
     * Данные подписываемого документа Base64
     * @type {string}
     */
    DataToSign: string,
    /**
    * Открыт ли попап выбора сертификатов
    * @type {boolean}
    */
    IsLoginWithCertificate: boolean,
    ChangeRememberMeState: boolean,
    IsLegalAgeCustom: boolean,
    NotifCustom: boolean,
    PolicyCustom: boolean,
    IsOpenCustomMenu: boolean,
    CustomActiveOption: boolean,
    CustomSelectArr: any
}

class SCCloseRowItem extends React.Component<any, IExtraDataDetails> {
    constructor(props: any) {
        super(props);
        this.state = {
            ShowValidation: false,
            Errors: {},
            IsButtonLoading: false,
            IsAgreedToTerms: false,
            Password: '',
            IsSomeOneOpen: props.openPopup,
            IsRegisterFormOpen: false,
            IsZNDAFormOpen: false,
            IsDefOpen: true,
            IsLoginFormOpen: false,
            IsFemaleChecked: false,
            IsMaleChecked: false,
            IsGoodRegist: false,
            IsNdaPopupOpen: false,
            IsNdaPopupOpen2: false,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4: false,
            IsNdaPopupOpen4_1: false,
            IsPaymentPopupOpen: true,
            IsNDAsignedUp: false,
            IsSignedNDA: false,
            IsPaid: false,
            IsLegalAge: false,
            IsLegalAgeCustom: false,
            NotifCustom: false,
            PolicyCustom: false,
            IsSignedOnNews: false,
            ChangeRememberMeState: false,
            IsOpenCustomMenu: false,
            CustomActiveOption: "Не выбрано",
            SelectedEntityType: "",
            Step: 1,
            User: "",
            URL_: "",
            Signature: "",
            ServiceId: 0,
            DataToSign: "",
            IsLoginWithCertificate: false,
            SimpleRegisterModel: null,
            AccountDetails: null,
            ProfileDetails: null,
            AvailableCompanyTypes: null,
            GlobalError: "",
            CustomSelectArr: [
                { name: "Не выбрано", isActive: true, idE: "" },
                { name: "Физическое лицо", isActive: false, idE: "individual" },
                { name: "Индивидуальный предприниматель", isActive: false, idE: "entrepreneur" },
                { name: "Юридическое лицо", isActive: false, idE: "legal" }
            ]
        } as IExtraDataDetails;
    }

    componentDidMount = () => {
        this.loadModel = this.loadModel.bind(this);
        //this.openEndForm = this.openEndForm.bind(this);
        this.loadModel((result) => {
            this.setState({
                ...this.state,
                AccountDetails: result.UpdatedItem.accountDetails,
                ProfileDetails: result.UpdatedItem.profileDetails,
                AvailableCompanyTypes: result.UpdatedItem.companyTypes,
                User: result.UpdatedItem.accountDetails.Email,
                Signature: result.UpdatedItem.signature,
                IsPaid: result.UpdatedItem.isPaid,
                SimpleRegisterModel: {
                    Password: "",
                    ConfirmPassword: "",
                    AccountEmail: "",
                    ConfirmAccountEmail: "",
                    AccountFullName: "",
                    Gender: false,
                    Success: false,
                    IsCompany: false,
                    CompanyName: "",
                    AcceptUserAgreement: false,
                    AllowNotifications: false,
                }
            }, () => {
                console.log("Гендер " + this.state.SimpleRegisterModel.Gender);
                if (this.state.AccountDetails.Email) {
                    this.setState(prevState => ({
                        //IsSomeOneOpen: true,
                        IsNdaPopupOpen: true
                    }), () => {
                        console.log(`IsNdaPopupOpen: ${this.state.IsNdaPopupOpen}, IsSomeOneOpen: ${this.state.IsSomeOneOpen}`);
                    })
                }
            })

            this.checkIsSignedDocument();
            this.checkOrderService(0); //без создания заказа услуг

            console.log("Подпись " + this.state.Signature);
            console.log("Пользователь может подписать " + AccountInfo.CurrentUserCanSign);
            console.log("Объект AccountDetails " + JSON.stringify(this.state.AccountDetails));
            console.log(JSON.stringify("Аккаунт инфо " + JSON.stringify(AccountInfo)));
        }, (error) => {
            console.log("error" + error);
        });
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (Object.keys(this.props.userName).length === 0) {
            return null;
        }
        return prevState.User;
    }

    //Получение модели данных для автозаполнения, если данные есть
    loadModel = async (successCallback, errorCallback) => {
        Fetcher("/ProfileApi/GetDetails", {})
            .then(res => res.json())
            .then(
                (result) => {
                    successCallback(result);
                },
                (error) => {
                    errorCallback(error);
                }
            );
    }

    //Сохранение данных в бд
    saveProfileData = async () => {
        console.log(`stateHandle ${this.state.ProfileDetails}`)
        if (this.validate(true)) {
            Fetcher("/ProfileApi/SaveProfileDetails",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.state.ProfileDetails)
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("handleFillData" + JSON.stringify(result));
                    }
                );
        }
    }

    //Добавление заказа на оплату услуг
    addOrderService = () => {
        let orderService: ICreateOrderServiceModel;
        this.getService(), () => {
            orderService = {
                OrderService: {
                    Price: 4000,
                    PaymentDate: new Date(),
                    AccountId: this.state.AccountDetails.AccountId,
                    OrderStatus: 0
                },
                IdServices: [this.state.ServiceId]
            }
            console.log("IdServices " + JSON.stringify(orderService.IdServices));
        };
        Fetcher("/OrderServiceApi/CreateOrder/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(orderService)
        })
            .then(res => res.json()
                .then(result => {
                    console.log(JSON.stringify(result));
                    console.log(`****************${result.UpdatedItem.OrderService.OrderStatus}******************`)
                    this.setState(prevState => ({
                        IsPaid: Boolean(result.UpdatedItem.OrderService.OrderStatus)
                    }));
                    console.log(JSON.stringify(this.state.IsPaid));
                }));
    }

    //Проверка состояния заказа на оплату услуг
    checkOrderService = (type) => {
        Fetcher(`/OrderServiceApi/GetOrderServicesByAccountId?`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "AccountId": this.state.AccountDetails.AccountId
            }
        })
            .then(res => res.json())
            .then(result => {
                console.log(JSON.stringify(result));
                if (!result.UpdatedItem || result.UpdatedItem.length <= 0) {
                    if (type === 1) {
                        this.addOrderService();
                        console.log("Заказ создавался");
                    } else {
                        console.log("Без создания заказа");
                    }
                } else {
                    console.log("Заказ не создавался");
                }
                this.setState(prevState => ({
                    IsSomeOneOpen: true,
                    IsNdaPopupOpen3: false
                }))
            });
    }

    //Получить токен
    getAntiforgeryToken = async () => {
        const tokenResponse = await Fetcher('/auth/getAntiForgeryToken', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const token = await tokenResponse.json();
        console.log(JSON.stringify(token));
        return token;
    }

    //регистрация
    register = async () => {
        try {
            const token = await this.getAntiforgeryToken();

            const response = await fetch('/auth/register/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'RequestVerificationToken': token
                },
                body: JSON.stringify(this.state.SimpleRegisterModel)
            });

            const result = await response.json();
            console.log("Данные регистрации", result);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    }

    //Отправка запроса для авторизации
    handleLogin = async () => {
        const token = await this.getAntiforgeryToken();
        Fetcher('/auth/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'RequestVerificationToken': token
            },
            body: JSON.stringify({
                UserName: this.state.AccountDetails.Email,
                Password: this.state.Password
            })
        })
            .then(response => response.json())
            .then(data => {
                window.location.reload();
            });
    }

    //Вход по сертификату
    loginWithCertificate = async () => {
        this.setState(prevState => ({
            IsLoginWithCertificate: true,
            IsLoginFormOpen: false
        }), () => {
            console.log("Вход по эцп " + this.state.IsLoginWithCertificate);
        });
    }

    //Формирование платёжки
    getBillPaymentServicePDF = async () => {
        console.log("Перед fetch");
        await this.getService(), () => {
            console.log("Сервис Id извне - " + this.state.ServiceId);
        };
        Fetcher(`/Profile/GetBillPaymentServicePDF?companyId=${this.state.ProfileDetails.CompanyId}&amountService=${4000}&serviceId=${this.state.ServiceId}`, {
            //serviceId на локалке поменять на 3, на проме на 2, возможная причина проблем с pdf. Либо вытянуть
            method: 'GET'
        })
            .then(res => res.blob())
            .then(result => {
                console.log("Результат fetch");
                const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
                window.open(url, '_blank');
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Счет_на_оплату.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    }

    //Получение тарифа "Бизнес"
    getService = async () => {
        await Fetcher("/ServiceApi/GetServiceByName?name=Бизнес", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result && result.UpdatedItem.ListServicesOrders.length > 0) {
                    console.log("Сервис по имени - " + JSON.stringify(result));
                    console.log("Сервис по имени ID - " + JSON.stringify(result.UpdatedItem.ListServicesOrders[0].ServiceId));
                    this.setState(prevState => ({
                        ServiceId: result.UpdatedItem.ListServicesOrders[0].ServiceId
                    }), () => {
                        console.log("Сервис по имени ID state - " + JSON.stringify(this.state.ServiceId));
                    });
                }
            });
    }

    //Проверка документа на наличие
    getDocument = (callback) => {
        fetcherGET("/DocumentApi/GetDocument?documentType=" + DocumentTypes.Contract + "&accountId=" + AccountInfo.Id, callback);
    }

    //Проверка, подписан ли документ
    checkIsSignedDocument = async () => {
        this.getDocument((result) => {
            console.log(JSON.stringify(result));
            if (result.UpdatedItem) {
                this.setState(prevState => ({
                    IsSignedNDA: result.UpdatedItem.IsSigned,
                    DataToSign: result.UpdatedItem.DataToSign
                }), () => {
                    console.log("Подписан ли документ - " + this.state.IsSignedNDA);
                    console.log("Данные для подписания в проверке подписан ли документ " + this.state.DataToSign);
                    console.log(`User ${this.state.User}, IsSignedNDA ${this.state.IsSignedNDA}, IsPaid ${this.state.IsPaid}`);

                    if (this.state.User != '' && this.state.IsSignedNDA && !this.state.IsPaid) {
                        this.setState(prevState => ({
                            IsSomeOneOpen: true,
                            IsNdaPopupOpen: false,
                            IsNdaPopupOpen2: false,
                            IsNdaPopupOpen3: true,
                            IsPaymentPopupOpen: true
                        }), () => {
                            console.log("Плашка должна поменяться");
                        })
                    }
                });


            }
        })
    }

    //Подписание соглашения
    toSignAgreement = async () => {
        console.log('toSignAgreement');

        //проверим не был ли ранее получен уже такой документ и сохранён в профиле пользователя
        // если он есть: то его и подпишем

        await this.getDocument(async (result) => {
            const userCanSign = AccountInfo.CurrentUserCanSign;
            if (result && result.Success) {
                console.log('result', result);
                if (userCanSign) {
                    await this.handleSignProtocol(result.UpdatedItem.docId, result.UpdatedItem.DataToSign, result.UpdatedItem.Signature);
                } else {
                    alert("Вы не можете подписать. Выберите ЭЦП в настройках профиля");
                }
            } else {
                console.log('else');
                const formData = new FormData();
                formData.append("firstName", this.state.ProfileDetails.FirstName);
                formData.append("secondName", this.state.ProfileDetails.LastName);
                formData.append("middleName", this.state.ProfileDetails.MiddleName);

                const LOCALE_URL = 'http://localhost:3000/create-doc';
                const PROD_URL = 'https://documentcreator.viomitra.ru/create-doc';

                //Fetcher(LOCALE_URL, {
                await Fetcher(PROD_URL, {
                    method: 'POST',
                    body: formData
                })
                    .then(res => res.arrayBuffer())
                    .then(async (result) => {
                        console.log("result" + result);
                        await this.saveDocument(result, async (result) => {
                            //this.handleSignProtocol(result.UpdatedItem.docId, result.UpdatedItem.DataToSign, result.UpdatedItem.Signature);
                            if (userCanSign) {
                                await this.handleSignProtocol(result.UpdatedItem.docId, result.UpdatedItem.DataToSign, result.UpdatedItem.Signature);
                            } else {
                                alert("Вы не можете подписать. Выберите ЭЦП в настройках профиля");
                            }

                        });
                    })
                    .catch((exception) => {
                        console.log('catch:', exception);
                        alert("Соглашение не подписано");
                    });
            }
        });
    }

    //сохранение документа
    saveDocument = (document, callback) => {

        // выполним загрузку файла на сервер
        const formData = new FormData();
        formData.append("DocumentFile", new Blob([document]));
        formData.append("DocumentType", String(DocumentTypes.Contract));
        formData.append("Comment", "Данный файл сгенерирован сервисом DocumentCreator");
        formData.append("Title", "Обязательство о неразглашении конфиденциальной информации");

        fetcherPOST("/DocumentApi/AddDocument", formData, (result) => {
            if (result == null) {
                console.log('LotRowItem::saveDocument -> не удалось сохранить документ');
                alert('Произошла ошибка. Попробуйте позже.');
            }
            else if (result.Success) {
                console.log('документ успешно сохранён на сервер');
                callback(result);
            }
            else if (result.Error) {
                console.log('LotRowItem::saveDocument -> произошла ошибка:', result.Error);
                alert('Произошла ошибка. Попробуйте позже.');
            }
        })
    }

    //подписание документа
    handleSignProtocol = async (docId, documentBase64, signature) => {
        console.log(`Функция подписания документа docId ${docId} documentBase64 ${documentBase64} signature ${signature}`);
        console.log(JSON.stringify("Аккаунт инфо во время подписания" + JSON.stringify(AccountInfo)));
        let thumbprint = AccountInfo.Thumbprint;
        await CADESCOMSignData("hdnSignInfo",
            "hdnSignInfo",
            thumbprint, true, "hdnExistingSignData", true, async (signInfo) => {
                console.log('!!!' + signInfo);

                await Fetcher("/DocumentApi/AddSignature",
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ SignInfo: signInfo, DocId: docId })
                    })
                    .then(async (res) => res.json())
                    .then(
                        async (result) => {
                            if (result.Success) {
                                this.setState(prevState => ({
                                    IsSomeOneOpen: true,
                                    IsNdaPopupOpen: false,
                                    IsNdaPopupOpen2: false,
                                    IsNdaPopupOpen3: true,
                                    IsPaymentPopupOpen: true
                                }), () => {
                                    console.log("Плашка должна поменяться");
                                })
                                alert('Вы успешно подписали соглашение');
                            }
                            else {
                                console.log('LotRowItem::handleSignProtocol -> произошла ошибка:', result.Error);
                                alert('Произошла ошибка: ' + result.Error);
                            }
                        },
                        (error) => {
                            console.log('LotRowItem::handleSignProtocol -> произошла ошибка:', error);
                            alert('Произошла ошибка: ' + error);
                        }
                    );
            }
        );
    }

    // Все функции обработки событий
    handleLoginEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            AccountDetails: {
                ...this.state.AccountDetails,
                Email: event.target.value
            }
        }, () => {
            console.log(this.state.AccountDetails.Email);
        });
    };

    //общая функция валидации
    validate = (show) => {
        let errors = {};
        let isFormValid = true;

        if (!this.state.IsNDAsignedUp && show) {
            errors['IsNDAsignedUp'] = ["Необходимо согласие с NDA"];
        }

        if (!this.validateINN(this.state.ProfileDetails.INN) && this.state.ProfileDetails.CompanyTypeId == 2) { //Если ИП
            errors['INN'] = ["Некорректный ИНН"];
        }

        if (!this.validateOGRNIP(this.state.ProfileDetails.OGRNIP) && this.state.ProfileDetails.CompanyTypeId != 3) { //Если не физическое лицо
            errors['OGRNIP'] = ["Некорректный ОГРНИП"];
        }

        if (!this.validateOGRN(this.state.ProfileDetails.OGRN) && this.state.ProfileDetails.CompanyTypeId != 3) {
            errors['OGRN'] = ['Некорректный ОГРН'];
        }

        if (this.state.ProfileDetails.CompanyTypeId == 3) { //Если физическое лицо
            if (!this.validateFirstAndSecondName(this.state.ProfileDetails.LastName)) {
                errors['LastName'] = ["Некорректная фамилия"];
            }
            if (!this.validateFirstAndSecondName(this.state.ProfileDetails.FirstName)) {
                errors['FirstName'] = ["Некорректное имя"];
            }
            if (!this.validateMiddleName(this.state.ProfileDetails.MiddleName)) {
                errors['MiddleName'] = ["Некорректное отчество"];
            }
        }

        if (Object.keys(errors).length > 0) {
            isFormValid = false;
        }

        this.setState({
            Errors: errors,
            ShowValidation: show,
        });

        return Object.keys(errors).length === 0;
    }

    //проверка имени и фамилии
    validateFirstAndSecondName = (name) => {
        return /^[А-ЯЁа-яё]+$/.test(name);
    }

    //проверка отчества
    validateMiddleName = (name) => {
        return /^[А-ЯЁа-яё]+$/.test(name) || /^$/.test(name);
    }

    //проверка ИНН
    validateINN = (inn) => {
        console.log('INN ' + inn + '. ' + /^[\d+]{10,12}$/.test(inn));
        return /^[\d+]{10,12}$/.test(inn);
    }

    //проверка ОГРНИП
    validateOGRNIP = (ogrnip) => {
        return /^[\d+]{15}$/.test(ogrnip);
    }

    //проверка ОГРН
    validateOGRN = (ogrn) => {
        return /^[\d+]{13}$/.test(ogrn);
    }

    //Получение ошибок валидации, если они есть
    getError = (field) => {
        if (this.state.Errors[field] && this.state.Errors[field].length > 0) {
            return this.state.Errors[field][0];
        }

        return null;
    }

    //проверка валидности
    isValid = (field) => {
        console.log("Поле " + field + ". " + !this.state.Errors[field] || this.state.Errors[field].length == 0);
        return !this.state.Errors[field] || this.state.Errors[field].length == 0;
    }

    //изменения пароля
    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevState => ({
            Password: event.target.value,
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                Password: event.target.value
            }
        }), () => {
            console.log("Пароль для авторизации" + this.state.Password);
            console.log("Пароль для регистрации " + this.state.SimpleRegisterModel.Password);
        });
    };

    //изменение ФИО при регистрации
    handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevState => ({
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                AccountFullName: event.target.value
            }
        }), () => {
            console.log("Полное имя " + this.state.SimpleRegisterModel.AccountFullName);
        })
    };

    //Изменение почты
    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevState => ({
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                AccountEmail: event.target.value
            }
        }), () => {
            console.log("Почта " + this.state.SimpleRegisterModel.AccountEmail);
        })
    };

    //изменение подтверждения почты
    handleConfirmEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevState => ({
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                ConfirmAccountEmail: event.target.value
            }
        }), () => {
            console.log("Подтверждение почты " + this.state.SimpleRegisterModel.ConfirmAccountEmail);
        })
    };

    //Чекбокс подписки на новости
    OTHERhandleNotificationCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prevState => ({
            IsSignedOnNews: event.target.checked,
            NotifCustom: true
        }), () => {
            console.log("Подписаться на новости " + this.state.IsSignedOnNews);
        })
    };

    //Изменение ИНН
    handleINN = (event) => {
        let inn = event.target.value;
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                INN: inn
            }
        }), () => {
            console.log(`INN ${this.state.ProfileDetails.INN}`)
        })
    }

    //изменение подтверждения пароля
    handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setConfirmPasswordValue(event.target.value);
        this.setState(prevState => ({
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                ConfirmPassword: event.target.value
            }
        }), () => {
            console.log("Подтверждение пароля" + this.state.SimpleRegisterModel.ConfirmPassword);
        });
    };

    //чекбокс подтверждения совершеннолетия
    handleAgeCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setAgeCheckboxValue(event.target.checked);
        this.setState(prevState => ({
            IsLegalAge: event.target.checked,
            IsLegalAgeCustom: true
        }), () => {
            console.log("18 лет " + this.state.IsLegalAge);
        });
    };

    //Подписался ли пользователь на новости при регистрации
    handleNotificationCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setNotificationCheckboxValue(event.target.checked);
        this.setState(prevState => ({
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                AllowNotifications: event.target.checked
            }
        }), () => {
            console.log("AllowNotification " + this.state.SimpleRegisterModel.AllowNotifications)
        });
    };

    handlePolicyCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setPolicyCheckboxValue(event.target.checked);
        this.setState(prevState => ({
            PolicyCustom: true,
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                AcceptUserAgreement: event.target.checked
            }
        }), () => {
            console.log("AcceptUserAgreement " + this.state.SimpleRegisterModel.AcceptUserAgreement);
        });
    };

    navigateToLotCard = () => {
        // Реализация перехода на карточку лота
    }

    handleFemaleChange = () => {
        //setIsFemaleChecked(true);
        //setIsMaleChecked(false);

        this.setState(prevState => ({
            IsFemaleChecked: true,
            IsMaleChecked: false,
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                Gender: true
            }
        }), () => {
            console.log("Мужчина - " + this.state.SimpleRegisterModel.Gender);
            console.log("Женщина - " + this.state.IsFemaleChecked);
        })
    };

    handleMaleChange = () => {
        //setIsFemaleChecked(false);
        //setIsMaleChecked(true);
        this.setState(prevState => ({
            IsMaleChecked: true,
            IsFemaleChecked: false,
            SimpleRegisterModel: {
                ...prevState.SimpleRegisterModel,
                Gender: false
            }
        }), () => {
            console.log("Мужчина - " + this.state.SimpleRegisterModel.Gender);
            console.log("Мужчина - " + this.state.IsMaleChecked);
        })
    };

    handleNdaCheckAndOpenPopup = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen: true
        }), () => {
            console.log("IsSomeOneOpen handleNdaCheckAndOpenPopup" + this.state.IsSomeOneOpen);
        });
    }

    openPaymentPopup = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsPaymentPopupOpen: true
        }));
    }

    sendOrderMessageToTelegram = () => {
        // Реализация отправки сообщения о заказе в телеграмм группу
    }

    openLoginForm = () => {
        this.setState(prevState => ({
            IsDefOpen: false,
            IsGoodRegist: false,
            IsLoginFormOpen: true
        }));

        console.log("Перейти к лоту. Email" + this.state.AccountDetails.Email);
        console.log("URL:::" + this.props.URL_);

        // Проверяем, что URL_ действительно существует перед использованием
        const ok_url = this.props.URL_ ? this.props.URL_ : '';

        if (ok_url != '') {
            window.location.href = this.props.URL_;
        }

        else {
            this.closeGoodPay();
        }
    }

    closeLoginForm = () => {
        this.setState(prevState => ({
            IsLoginFormOpen: false,
            IsSomeOneOpen: false
        }));
        this.closePopup();
    }

    openRegisterForm = () => {
        this.setState(prevState => ({
            IsDefOpen: false,
            IsRegisterFormOpen: true
        }));
    }

    openZNDAForm = () => {
        this.setState(prevState => ({
            IsNdaPopupOpen: false,
            IsZNDAFormOpen: true,
            IsSomeOneOpen: true
        }));
    }

    closeZNDAForm = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: false,
            IsZNDAFormOpen: false,
        }));
        //ЗНДА
        this.closePopup();
    }

    closePopup = () => {
        this.props.closePopup();
    }

    openEndForm = () => {
        this.toSignAgreement(), () => {
            if (this.state.IsSignedNDA) {
                this.setState(prevState => ({
                    IsSomeOneOpen: true,
                    IsNdaPopupOpen2: false,
                    IsNdaPopupOpen3: true
                }), () => {
                    console.log("Попап открылся c платёжками должен открыться");
                });
            } else {
                alert("Вы не подписали соглашение");
            }
        };
    }

    openPay1Form = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4: true
        }));
    }

    openPay1Form_1 = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4_1: true
        }));
    }

    openPay2Form = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4: true
        }));
    }

    openPay2Form_1 = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4_1: true
        }));
    }

    // ------------------------------------------------------------------------------------------Тут закомментить 2 вызова------------------------------------

    openPay3Form = () => {
        this.getBillPaymentServicePDF();
        this.checkOrderService(1); //С созданием заказа услуг
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4: true
        }));
    }

    // ------------------------------------------------------------------------------------------Тут закомментить 2 вызова------------------------------------

    openPay3Form_1 = () => {
        this.getBillPaymentServicePDF();
        this.checkOrderService(1); //С созданием заказа услуг
        this.setState(prevState => ({
            IsSomeOneOpen: true,
            IsNdaPopupOpen3: false,
            IsNdaPopupOpen4_1: true
        }));
    }

    closeDefForm = () => {
        this.setState(prevState => ({
            IsDefOpen: false,
            IsSomeOneOpen: false
        }));
        this.closePopup();
    }

    closeRegisterForm = () => {
        this.setState(prevState => ({
            IsRegisterFormOpen: false,
            IsSomeOneOpen: false
        }));
        this.closePopup();
    }

    closeNdaPopup = () => {
        this.setState(prevState => ({
            IsNdaPopupOpen: false,
            IsSomeOneOpen: false
        }));
        this.closePopup();
    }

    closeNdaPopup2 = () => {
        this.setState(prevState => ({
            IsNdaPopupOpen2: false,
            IsSomeOneOpen: false
        }));
        this.closePopup();
    }

    closeNdaPopup3 = () => {
        this.setState(prevState => ({
            IsNdaPopupOpen3: false,
            IsSomeOneOpen: false
        }));
        this.closePopup();
    }

    LogIn = () => {
        if (!this.state.AccountDetails.Email || !this.state.Password) {
            alert('Пожалуйста, заполните все поля для входа.');
            return;
        }

        //Логика входа
        this.handleLogin();
        //Если вошел - подписание NDA:
        this.closeLoginForm();
        this.handleNdaCheckAndOpenPopup();
    }


    endRegistr = () => {
        if (!this.state.SimpleRegisterModel.AccountFullName || !this.state.SimpleRegisterModel.AccountEmail || !this.state.SimpleRegisterModel.ConfirmAccountEmail
            || !this.state.SimpleRegisterModel.Password || !this.state.SimpleRegisterModel.ConfirmPassword || !this.state.IsLegalAge
            || !this.state.SimpleRegisterModel.AcceptUserAgreement || (!this.state.IsFemaleChecked && !this.state.IsMaleChecked) || !this.state.IsSignedOnNews) {
            // console.log(fullNameValue, emailValue, confirmEmailValue, passwordValue, confirmPasswordValue, ageCheckboxValue, notificationCheckboxValue, policyCheckboxValue);
            alert('Пожалуйста, заполните все поля и выберите все чекбоксы для завершения регистрации.');
            return;
        }

        if (this.state.SimpleRegisterModel.AccountEmail !== this.state.SimpleRegisterModel.ConfirmAccountEmail) {
            alert('Пожалуйста, убедитесь, что указанные Email совпадают.');
            return;
        }

        if (this.state.SimpleRegisterModel.Password !== this.state.SimpleRegisterModel.ConfirmPassword) {
            alert('Пожалуйста, убедитесь, что пароли совпадают.');
            return;
        }

        this.register();
        // Если всё заполнено, то создаем аккаунт и направляем на форму "Успешная регистрация"
        this.setState(prevState => ({
            IsRegisterFormOpen: false,
            IsGoodRegist: true
        }))
    }

    openNdaPopupOpen3 = () => {
        this.setState(prevState => ({
            IsSomeOneOpen: true
        }));
    }

    closeGoodRegist = () => {
        this.setState(prevState => ({
            IsGoodRegist: false,
            IsSomeOneOpen: false
        }));
    }

    closeGoodPay = () => {
        this.setState(prevState => ({
            IsNdaPopupOpen4: false,
            IsSomeOneOpen: false
        }));;
    }

    closeGoodPay_1 = () => {
        this.setState(prevState => ({
            IsNdaPopupOpen4_1: false,
            IsSomeOneOpen: false
        }));;
    }

    handleNdaSigning = () => {
        this.setState(prevState => ({
            Step: prevState.Step + 1
        }));
    }

    // Функция обработки изменения типа субъекта
    handleEntityTypeChange = (params) => {
        const selectedValue = params;
        let companyId;

        this.setState(
            { SelectedEntityType: selectedValue },
            () => {
                switch (selectedValue) {
                    case 'individual':
                        companyId = 3;
                        break;
                    case 'entrepreneur':
                        companyId = 2;
                        break;
                    case 'legal':
                        companyId = 1;
                        break;
                    default:
                        companyId = 0;
                }

                this.setState(prevState => ({
                    ProfileDetails: {
                        ...prevState.ProfileDetails,
                        CompanyTypeId: companyId
                    }
                }), () => {
                    console.log("SelectedEntityType: " + this.state.SelectedEntityType);
                    console.log("CompanyTypeId: " + this.state.ProfileDetails.CompanyTypeId);
                });
            }
        );
    }

    // Функции обработки изменений для Физического лица
    handleSurnameChange = (event) => {
        const surname = event.target.value;
        // Обработка изменения фамилии
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                LastName: surname
            }
        }));
    }

    handleNameChange = (event) => {
        const name = event.target.value;
        // Обработка изменения имени
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                FirstName: name
            }
        }));
    }

    handlePatronymicChange = (event) => {
        const patronymic = event.target.value;
        // Обработка изменения отчества
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                MiddleName: patronymic
            }
        }));
    }

    handleBirthdateChange = (event) => {
        const birthdate = event.target.value;
        // Обработка изменения даты рождения
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                Birthday: birthdate
            }
        }));
    }

    handleDocumentNumberChange = (event) => {
        const documentNumber = event.target.value;
        console.log("event " + documentNumber);
        // Обработка изменения серии и номера документа
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                PassportSeries: documentNumber
            }
        }), () => {
            console.log('ProfileDetails.PassportSeries ' + this.state.ProfileDetails.PassportSeries);
        });
    }

    handleIssueDateChange = (event) => {
        const issueDate = event.target.value;
        // Обработка изменения даты выдачи документа
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                PassportDate: issueDate
            }
        }));
    }

    handleIssuedByChange = (event) => {
        const issuedBy = event.target.value;
        // Обработка изменения органа, выдавшего документ
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                PassportPlace: issuedBy
            }
        }));
    }

    handleResidentialAddressChange = (event) => {
        const residentialAddress = event.target.value;
        // Обработка изменения адреса места жительства
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                RegAddress: residentialAddress
            }
        }));
    }

    handleCurrentAddressChange = (event) => {
        const currentAddress = event.target.value;
        // Обработка изменения фактического адреса проживания
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                FactAddress: currentAddress
            }
        }));
    }

    // Функции обработки изменений для Индивидуального предпринимателя
    // Аналогичные функции для Индивидуального предпринимателя...

    // Функции обработки изменений для Юридического лица
    // Аналогичные функции для Юридического лица...

    // Функция обработки чекбокса NDA
    handleNdaCheckboxChange = (event) => {
        const isNdaChecked = event.target.checked;
        // Обработка изменения состояния чекбокса NDA
        this.setState(prevState => ({
            IsNDAsignedUp: !this.state.IsNDAsignedUp
        }));
    }

    // Функция сохранения изменений
    saveChanges = () => {
        let isValidateStatus = this.validate(true);
        if (isValidateStatus) {
            this.saveProfileData();
            this.setState(prevState => ({
                IsZNDAFormOpen: false,
                IsNdaPopupOpen2: true
            }));
        }
    }

    // Функция обработки изменения наименования ИП
    handleEntrepreneurNameChange = (event) => {
        const entrepreneurName = event.target.value;
        // Обработка изменения наименования ИП
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                CompanyName: entrepreneurName
            }
        }));
        console.log(this.state.ProfileDetails.CompanyName);
    }

    // Функция обработки изменения ОГРНИП
    handleOgrnipChange = (event) => {
        const ogrnip = event.target.value;
        console.log('ogrnip ' + ogrnip);
        // Обработка изменения ОГРНИП
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                OGRNIP: ogrnip
            }
        }), () => {
            console.log("OGRNIP " + this.state.ProfileDetails.OGRNIP);
        });
    }

    handleOgrnChange = (event) => {
        const ogrn = event.target.value;
        console.log('ogrn ' + ogrn);
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                OGRN: ogrn
            }
        }), () => {
            console.log('OGRN ' + this.state.ProfileDetails.OGRN);
        });
    }

    // Функция обработки изменения наименования юридического лица
    handleLegalNameChange = (event) => {
        const legalName = event.target.value;
        // Обработка изменения наименования юридического лица
        this.setState(prevState => ({
            ProfileDetails: {
                ...prevState.ProfileDetails,
                CompanyName: legalName
            }
        }));
    }

    formatDate = (date) => {
        if (!date) {
            return "";
        }

        const selectedDate = new Date(date);
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
        const day = selectedDate.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    rememberMeChange = () => {
        this.setState(prevState => ({
            ChangeRememberMeState: !prevState.ChangeRememberMeState
        }), () => {
            console.log('RememberMe');
        });
    }

    changeCustomSelect = (eo) => {
        this.setState(prevState => ({
            CustomActiveOption: eo.target.textContent,
            IsOpenCustomMenu: !this.state.IsOpenCustomMenu,
            CustomSelectArr: [
                { name: "Не выбрано", isActive: this.checkActiveOption(eo.target.textContent, "Не выбрано"), idE: "" },
                { name: "Физическое лицо", isActive: this.checkActiveOption(eo.target.textContent, "Физическое лицо"), idE: "individual" },
                { name: "Индивидуальный предприниматель", isActive: this.checkActiveOption(eo.target.textContent, "Индивидуальный предприниматель"), idE: "entrepreneur" },
                { name: "Юридическое лицо", isActive: this.checkActiveOption(eo.target.textContent, "Юридическое лицо"), idE: "legal" }
            ]
        }), () => {
            console.log('Выбрано:' + this.state.CustomActiveOption);
        });




        this.handleEntityTypeChange(eo.target.id)
    }

    checkActiveOption = (pressed, name) => {
        if (pressed === name) {
            return true
        } else {
            return false
        }
    }



    render = () => {
        return (
            <React.Fragment>
                {<div className="popup-container" style={{ display: this.state.IsSomeOneOpen ? 'block' : 'none', overflowY: 'auto', maxHeight: '75vh' }}>
                    {this.state.User === '' && this.state.IsDefOpen && <div className="wrap-step wrap-step1">
                        <div className="close-btn"><button onClick={this.closeDefForm}>
                            {/* <img src="../../images/close.svg" /> */}
                            {/* временно вставила svg сюда, потом, наверное, будет импорт */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#690097"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button></div>
                        <h1 className="header-text-cl-kk">ХОТИТЕ УЗНАТЬ ЦЕНУ? <hr></hr> </h1>
                        <div className="Balls-close">
                            <span className="ball-1"></span>
                            <span className="line-1"></span>
                            <span className="ball-2"></span>
                            <span className="line-2"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Заполнение профиля и подписание NDA</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Тариф «Бизнес»</p>
                            </div>
                        </div>
                        <div className="center">
                            <button className="log-in-cl-kk log-button" onClick={this.openLoginForm}>Войти</button>
                            <button className="sign-in-cl-kk" onClick={this.openRegisterForm}>Зарегистрироваться</button>
                        </div>
                        <p className="downblock-text-kk">Данный лот находится в&nbsp;категории &laquo;Закрытые продажи&raquo;. Для получения подробной информации о&nbsp;лоте необходимо пройти 3&nbsp;шага!</p>
                    </div>}

                    {this.state.User == '' && this.state.IsLoginFormOpen && <div className="wrap-login" >
                        <div className="close-btn">
                            <button className="closeBtn-log-in" type="button" onClick={this.closeLoginForm}>
                                {/* <img src="../../images/close.svg" /> */}
                                {/* временно вставила svg сюда, потом, наверное, будет импорт */}
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#690097"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                            </button>
                        </div>
                        <h2 className="login-header-log-in">ВХОД В ЛИЧНЫЙ КАБИНЕТ <hr></hr> </h2>
                        <div className="input-container">
                            <label htmlFor="loginEmail">Логин или Email</label>
                            <input type="text" id="loginEmail" onChange={this.handleLoginEmailChange} placeholder="Логин или Email" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Пароль</label>
                            <input type="password" id="password" onChange={this.handlePasswordChange} placeholder="*********" />
                        </div>
                        <div className="checkbox-forgot-log-in">
                            <div className="checkbox-div">
                                <div onClick={this.rememberMeChange} className={this.state.ChangeRememberMeState ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="rememberMe"></div>
                                <label htmlFor="rememberMe">Запомнить меня</label>
                            </div>
                            <a href="#" className="forgot-password-log-in">Забыли пароль?</a>
                        </div>
                        <button className="log-in-cl-kk-log-in" onClick={this.LogIn}>Войти в профиль</button>
                        <button className="login-ecp-log-in" onClick={this.loginWithCertificate}>Вход по ЭЦП</button>
                    </div>}

                    {this.state.IsLoginWithCertificate && <div ><LoginWithCert /></div>}

                    {this.state.IsRegisterFormOpen && <div className="wrap-registration">
                        <div className="close-btn">
                            <button onClick={this.closeRegisterForm}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#690097"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                            </button>
                        </div>
                        <h2 className="registration-header">РЕГИСТРАЦИЯ <hr></hr> </h2>
                        <div className="input-container">
                            <label htmlFor="fullName">ФИО</label>
                            <input type="text" id="fullName" onChange={this.handleFullNameChange} placeholder="ФИО" />
                        </div>
                        <div className="input-container">
                            <label>Пол</label>
                            <div className="gender-checkboxes">
                                <div className='checkbox-div'>
                                    <div onClick={this.handleFemaleChange} className={this.state.IsFemaleChecked ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="femaleCheckbox"></div>
                                    <label htmlFor="femaleCheckbox">женский</label>
                                </div>
                                <div className='checkbox-div'>
                                    <div onClick={this.handleMaleChange} className={this.state.IsMaleChecked ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="maleCheckbox"></div>
                                    <label htmlFor="maleCheckbox">мужской</label>
                                </div>
                            </div>
                        </div>
                        <div className="input-container">
                            <label htmlFor="email">E-mail</label>
                            <input type="text" id="email" onChange={this.handleEmailChange} placeholder="info@mail.ru" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="confirmEmail">Повторите E-mail</label>
                            <input type="text" id="confirmEmail" onChange={this.handleConfirmEmailChange} placeholder="info@mail.ru" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Пароль</label>
                            <input type="password" id="password" onChange={this.handlePasswordChange} placeholder="не менее 8-ми символов" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="confirmPassword">Повторите пароль</label>
                            <input type="password" id="confirmPassword" onChange={this.handleConfirmPasswordChange} placeholder="не менее 8-ми символов" />
                        </div>
                        <div className="checkbox-div">
                            <div onClick={this.handleAgeCheckboxChange} className={this.state.IsLegalAgeCustom ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="ageCheckbox"></div>
                            <label htmlFor="ageCheckbox"> Мне исполнилось 18 лет </label>
                        </div>
                        <div className="checkbox-div">
                            <div onClick={this.OTHERhandleNotificationCheckboxChange} className={this.state.NotifCustom ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="notificationCheckbox"></div>
                            <label htmlFor="notificationCheckbox">Хочу получать уведомления и&nbsp;новости на&nbsp;указанный e-mail</label>
                        </div>
                        <div className="checkbox-div">
                            <div onClick={this.handlePolicyCheckboxChange} className={this.state.PolicyCustom ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="policyCheckbox"></div>
                            <label htmlFor="policyCheckbox"> Ознакомлен и&nbsp;согласен с&nbsp;<a href="#">Политикой обработки персональных данных</a> и&nbsp;принимаю <a href="#">Пользовательское соглашение</a>
                            </label>
                        </div>
                        <button className="register-btn" onClick={this.endRegistr}>Завершить регистрацию</button>
                    </div>}

                    {this.state.IsGoodRegist && <div className="wrap-congrats">
                        <button className="closeBtn-log-in" type="button" onClick={this.closeGoodRegist}>
                            {/* <img src="../../images/close.svg" /> */}
                            {/* временно вставила svg сюда, потом, наверное, будет импорт */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button>
                        <h2 className="login-header-log-in">ПОЗДРАВЛЯЕМ! <hr></hr> </h2>
                        <p className="text-good-r">Вы успешно зарегистрировались на платформе VIOMITRA</p>
                        <button className="log-in-cl-kk-log-in" onClick={this.openLoginForm}>Войти</button>
                    </div>}

                    {this.state.User != '' && this.state.IsSignedNDA === false && this.state.IsNdaPopupOpen && <div className="wrap-step wrap-step2" >
                        <div className="close-btn"><button onClick={this.closeNdaPopup}>
                            {/* <img src="../../images/close.svg" /> */}
                            {/* временно вставила svg сюда, потом, наверное, будет <img src="../../images/close.svg" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button></div>
                        <h1 className="header-text-cl-kk">ЗАПОЛНЕНИЕ ПРОФИЛЯ И ПОДПИСАНИЕ NDA <hr></hr> </h1>
                        <div className="Balls-close">
                            <span className="ball-1 b-1-comp"></span>
                            <span className="line-1 l-1-comp"></span>
                            <span className="ball-2"></span>
                            <span className="line-2"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Заполнение профиля и подписание NDA</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Тариф «Бизнес»</p>
                            </div>
                        </div>
                        <div className="center">
                            <button className="log-in-cl-kk" onClick={this.openZNDAForm}>Заполнить профиль и подписать NDA</button>
                        </div>
                        <p className="downblock-text-kk downblock-firstComp">Данный лот находится в&nbsp;категории &laquo;Закрытые продажи&raquo;. Для получения подробной информации о&nbsp;лоте необходимо заполнить профиль, ознакомиться и&nbsp;согласиться с&nbsp;<a href="https://realty.viomitra.ru/Document/GuideDocument?guideName=DocumentNDA">NDA</a></p>
                    </div>}

                    {this.state.User != '' && this.state.IsZNDAFormOpen && <div className="wrap-profile-nda">
                        <div className="close-btn">
                            <button onClick={this.closeZNDAForm}>
                                {/* <img src="../../images/close.svg" /> */}
                                {/* временно вставила svg сюда, потом, наверное, будет импорт */}
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                            </button>
                        </div>
                        <h2 className="registration-header">Заполнение профиля и подписание NDA <hr></hr> </h2>
                        {/* Этого блока нет в Фигме
                <div className="nda-pred-cont">
                    <div className="nda-info">
                        <img src="../../images/nda.svg" />
                        <p>Необходимо ниже ознакомиться и согласиться с <a href="#">NDA</a></p>
                    </div>
                </div> */}
                        <div className="form-content">
                            {/* Выпадающий список */}
                            <div className="input-container">
                                <label htmlFor="entityType">Тип пользователя</label>
                                <div className="customSelect3">
                                    <div className="activeOption" onClick={() => this.setState({ IsOpenCustomMenu: !this.state.IsOpenCustomMenu })}> {this.state.CustomActiveOption} <img src="../../main-images/customSelectIcon.svg"></img> </div>
                                    {this.state.IsOpenCustomMenu &&
                                        <div className="customOptionMenu">
                                            {this.state.CustomSelectArr.map(optionE => (
                                                <span
                                                    onClick={this.changeCustomSelect}
                                                    className={`customOptionItem ${optionE.isActive ? "activeOptionMenu" : ""}`}
                                                    id={optionE.idE}
                                                    key={optionE.idE}
                                                >
                                                    {optionE.name}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <select id="entityType" onChange={this.handleEntityTypeChange}>
                                    <option value="">Не выбрано</option>
                                    <option value="individual">Физическое лицо</option>
                                    <option value="entrepreneur">Индивидуальный предприниматель</option>
                                    <option value="legal">Юридическое лицо</option>
                                </select>
                            </div>
                        </div>

                        {/* Форма для Физического лица */}
                        {this.state.SelectedEntityType === 'individual' && (
                            <div className="individual-form nda-form">
                                <div className="input-container">
                                    <label htmlFor="surname">Фамилия</label>
                                    <input type="text" id="surname" onChange={this.handleSurnameChange} placeholder="Введите данные"
                                        value={this.state.ProfileDetails.LastName ? this.state.ProfileDetails.LastName : ""} />
                                    {/*disabled={this.state.ProfileDetails.LastName ? true : false}*/}

                                </div>
                                <div className="input-container">
                                    <label htmlFor="name">Имя</label>
                                    <input type="text" id="name" onChange={this.handleNameChange} placeholder="Введите данные"
                                        value={this.state.ProfileDetails.FirstName ? this.state.ProfileDetails.FirstName : ""} />
                                    {/*disabled={this.state.ProfileDetails.FirstName ? true : false}*/}
                                </div>
                                <div className="input-container">
                                    <label htmlFor="patronymic">Отчество</label>
                                    <input type="text" id="patronymic" onChange={this.handlePatronymicChange} placeholder="Введите данные"
                                        value={this.state.ProfileDetails.MiddleName ? this.state.ProfileDetails.MiddleName : ""} />
                                    {/*disabled={this.state.ProfileDetails.MiddleName ? true : false}*/}
                                </div>
                                <div className="input-container hb-cont">
                                    <label htmlFor="birthdate">Дата рождения</label>
                                    <input
                                        type="date" id="birthdate" onChange={this.handleBirthdateChange} placeholder="01-01-2021"
                                        value={this.state.ProfileDetails.Birthday ? this.formatDate(this.state.ProfileDetails.Birthday) : ""}
                                    />
                                    {/*<button className="input-group-addon btn"><span className="m-icon-calender calender-btn"></span></button>
                                    disabled={this.state.ProfileDetails.Birthday ? true : false}

                                    <label htmlFor="birthdate">Дата рождения</label>
                                    <div className="input-custom input-custom-datepicker input-group date" id="datetimepicker_bd">
                                        <input type="date"
                                            id="birthdate"
                                            onChange={this.handleBirthdateChange}
                                            className="form-control app-form-control white-bg"
                                            placeholder="01-01-2021"
                                            value={this.state.ProfileDetails.Birthday ? this.formatDate(this.state.ProfileDetails.Birthday) : ""} />
                                        <button className="input-group-addon btn">
                                            <span className="m-icon-calender calender-btn"></span>
                                        </button>
                                    </div>*/}
                                </div>


                                <div className="passport">
                                    <div className="input-container">
                                        <label htmlFor="documentNumber">Серия, № документа</label>
                                        <input type="text" id="documentNumber" onChange={this.handleDocumentNumberChange} placeholder="60 01 357986"
                                            value={this.state.ProfileDetails.PassportSeries ? this.state.ProfileDetails.PassportSeries : ""} />
                                        {/*disabled={this.state.ProfileDetails.PassportSeries ? true : false}*/}
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="issueDate">Дата выдачи</label>
                                        <input type="date" id="issueDate" onChange={this.handleIssueDateChange} placeholder="01-01-2021"
                                            value={this.state.ProfileDetails.PassportDate ? this.formatDate(this.state.ProfileDetails.PassportDate) : ""} />
                                        {/*<button className="input-group-addon btn"><span className="m-icon-calender calender-btn"></span></button>
                                        disabled={this.state.ProfileDetails.PassportDate ? true : false}*/}
                                    </div>
                                </div>
                                <div className="input-container">
                                    <label htmlFor="issuedBy">Кем выдан документ</label>
                                    <input type="text" id="issuedBy" onChange={this.handleIssuedByChange} placeholder="Введите данные"
                                        value={this.state.ProfileDetails.PassportPlace ? this.state.ProfileDetails.PassportPlace : ""} />
                                    {/* disabled={this.state.ProfileDetails.PassportPlace ? true : false}*/}
                                </div>
                                <div className="input-container">
                                    <label htmlFor="residentialAddress">Адрес регистрации</label>
                                    <input type="text" id="residentialAddress" onChange={this.handleResidentialAddressChange} placeholder="Введите данные"
                                        value={this.state.ProfileDetails.RegAddress ? this.state.ProfileDetails.RegAddress : ""} />
                                    {/*disabled={this.state.ProfileDetails.RegAddress ? true : false}*/}
                                </div>
                                <div className="input-container">
                                    <label htmlFor="currentAddress">Адрес фактического проживания</label>
                                    <input type="text" id="currentAddress" onChange={this.handleCurrentAddressChange} placeholder="Введите данные"
                                        value={this.state.ProfileDetails.FactAddress ? this.state.ProfileDetails.FactAddress : ""} />
                                    {/*disabled={this.state.ProfileDetails.FactAddress ? true : false}*/}
                                </div>
                                <div className="checkbox-div">
                                    <div onClick={this.handleNdaCheckboxChange} className={this.state.IsNDAsignedUp ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="ndaCheckbox"></div>
                                    <label htmlFor="ndaCheckbox">Я ознакомлен(-на) и согласен(-на) с <a href="https://realty.viomitra.ru/Document/GuideDocument?guideName=DocumentNDA">NDA</a></label>
                                </div>

                                {this.state.ShowValidation && this.state.Errors['IsNDAsignedUp'] && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('IsNDAsignedUp')}</p>
                                )}
                                {this.state.ShowValidation && this.state.Errors['LastName'] && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('LastName')}</p>
                                )}
                                {this.state.ShowValidation && this.state.Errors['FirstName'] && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('FirstName')}</p>
                                )}
                                {this.state.ShowValidation && this.state.Errors['MiddleName'] && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('MiddleName')}</p>
                                )}

                                <button className="save-btn" onClick={this.saveChanges}
                                    style={{
                                        backgroundColor:
                                            this.state.ProfileDetails.LastName &&
                                                this.state.ProfileDetails.FirstName &&
                                                this.state.ProfileDetails.Birthday &&
                                                this.state.ProfileDetails.PassportSeries &&
                                                this.state.ProfileDetails.PassportDate &&
                                                this.state.ProfileDetails.PassportPlace &&
                                                this.state.ProfileDetails.RegAddress &&
                                                this.state.ProfileDetails.FactAddress &&
                                                this.state.IsNDAsignedUp ?
                                                '#7B61F8' : 'gray'
                                    }}
                                >Сохранить изменения</button>
                            </div>
                        )}

                        {/* Форма для Индивидуального предпринимателя */}
                        {this.state.SelectedEntityType === 'entrepreneur' && (
                            <div className="entrepreneur-form nda-form">
                                <div className="input-container">
                                    <label htmlFor="entrepreneurName">Наименование ИП</label>
                                    <input type="text" id="entrepreneurName" onChange={this.handleEntrepreneurNameChange} placeholder="ИП Иванов Иван Иванович"
                                        value={this.state.ProfileDetails.CompanyName ? this.state.ProfileDetails.CompanyName : ""} />
                                    {/*disabled={this.state.ProfileDetails.CompanyName ? true : false}*/}
                                </div>
                                <div className="input-container">
                                    <label htmlFor="inn">ИНН</label>
                                    <input type="text" id="inn" placeholder="232343543543" onChange={this.handleINN} />
                                </div>
                                <div className="input-container">
                                    <label htmlFor="ogrnip">ОГРНИП</label>
                                    <input type="text" id="ogrnip" onChange={this.handleOgrnipChange} placeholder="574896545365436"
                                        value={this.state.ProfileDetails.OGRNIP ? this.state.ProfileDetails.OGRNIP : ""} />
                                    {/* disabled={this.state.ProfileDetails.OGRNIP ? true : false}*/}
                                </div>
                                <div className="checkbox-container">
                                    <div onClick={this.handleNdaCheckboxChange} className={this.state.IsNDAsignedUp ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="ndaCheckbox"></div>
                                    <label htmlFor="ndaCheckbox">Я ознакомлен(-на) и согласен(-на) с <a href="https://realty.viomitra.ru/Document/GuideDocument?guideName=DocumentNDA">NDA</a></label>
                                </div>
                                {/*<button disabled={ this.state.ProfileDetails.OGRNIP && this.state.ProfileDetails.CompanyName ? false : true } */}
                                {/*    className="save-btn" onClick={this.saveChanges} */}
                                {/*    style={{ backgroundColor: this.state.ProfileDetails.OGRNIP ? '#7B61F8' : 'gray'}}>Сохранить изменения</button>*/}
                                {this.state.ShowValidation && this.state.Errors['INN'] && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('INN')}</p>
                                )}
                                {this.state.ShowValidation && !this.isValid('OGRNIP') && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError("OGRNIP")}</p>)}
                                <button disabled={this.state.ProfileDetails.OGRNIP && this.state.ProfileDetails.CompanyName && this.state.IsNDAsignedUp ? false : true}
                                    className="save-btn" onClick={this.saveChanges}
                                    style={{ backgroundColor: this.state.ProfileDetails.OGRNIP && this.state.ProfileDetails.CompanyName && this.state.IsNDAsignedUp ? '#7B61F8' : 'gray' }}>Сохранить изменения</button>
                                {/*<button className="save-btn" onClick={this.saveChanges}>Сохранить изменения</button>*/}
                            </div>
                        )}

                        {/* Форма для Юридического лица */}
                        {this.state.SelectedEntityType === 'legal' && (
                            <div className="legal-form nda-form">
                                <div className="input-container">
                                    <label htmlFor="legalName">Наименование юридического лица</label>
                                    <input type="text" id="legalName" onChange={this.handleLegalNameChange} placeholder="ООО Митроша"
                                        value={this.state.ProfileDetails.CompanyName ? this.state.ProfileDetails.CompanyName : ""}
                                    />
                                    {/*disabled={this.state.ProfileDetails.CompanyName ? true : false}*/}
                                </div>
                                <div className="input-container">
                                    <label htmlFor="ogrn">ОГРН</label>
                                    <input type="text" id="ogrn" onChange={this.handleOgrnChange} placeholder="7565965456864"
                                        value={this.state.ProfileDetails.OGRN ? this.state.ProfileDetails.OGRN : ""} />
                                    {/*disabled={this.state.ProfileDetails.OGRN ? true : false}*/}
                                </div>
                                <div className="checkbox-container">
                                    <div onClick={this.handleNdaCheckboxChange} className={this.state.IsNDAsignedUp ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="ndaCheckbox"></div>
                                    <label htmlFor="ndaCheckbox">Я ознакомлен(-на) и согласен(-на) с <a href="https://realty.viomitra.ru/Document/GuideDocument?guideName=DocumentNDA">NDA</a></label>
                                </div>
                                {this.state.ShowValidation && this.state.Errors['INN'] && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('INN')}</p>
                                )}
                                {this.state.ShowValidation && this.isValid('OGRN') && (
                                    <p className="error-message"> <img src="../../../main-images/warning.svg"></img> {this.getError('OGRN')}</p>
                                )}
                                <button className="save-btn" style={{ backgroundColor: this.state.ProfileDetails.OGRN && this.state.ProfileDetails.CompanyName && this.state.IsNDAsignedUp ? '#7B61F8' : 'gray' }} onClick={this.saveChanges}>Сохранить изменения</button>
                            </div>
                        )}
                    </div>}



                    {this.state.IsNdaPopupOpen2 && <div className="wrap-step wrap-step3">
                        <div className="close-btn"><button onClick={this.closeNdaPopup2}>
                            {/* <img src="../../images/close.svg" /> */}
                            {/* временно вставила svg сюда, потом, наверное, будет <img src="../../images/close.svg" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button></div>
                        <h1 className="header-text-cl-kk">ПЕРЕХОД НА ТАРИФ «БИЗНЕС» <hr></hr> </h1>
                        <div className="Balls-close">
                            <span className="ball-1 b-1-comp"></span>
                            <span className="line-1 l-1-comp"></span>
                            <span className="ball-2 b-2-comp"></span>
                            <span className="line-2 l-2-comp"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Заполнение профиля и подписание NDA</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Тариф «Бизнес»</p>
                            </div>
                        </div>
                        <div className="center">
                            <button className="log-in-cl-kk" onClick={() => this.openEndForm()}>Хочу активировать сейчас!</button>
                        </div>
                        <input type="hidden" id="hdnSignInfo" value={this.state.DataToSign} />
                        <input type="hidden" id="hdnExistingSignData" value="" />
                        <p className="downblock-text-kk">Данный лот находится в&nbsp;категории &laquo;Закрытые продажи&raquo;. Для просмотра карточки лота перейдите на&nbsp;тариф &laquo;Бизнес&raquo;. <a href="#">Подробности о&nbsp;тарифе.</a></p>
                    </div>}

                    {/*Оплата*/}
                    {!this.state.IsPaid && this.state.IsNdaPopupOpen3 && <div className="wrap-business">
                        {/*<div className="close-but"><button onClick={this.closeNdaPopup3}><img src="../../../images/close.svg" /></button></div>*/}
                        <div className="close-btn"><button onClick={this.closeNdaPopup3}>
                            {/* <img src="../../images/close.svg" /> */}
                            {/* временно вставила svg сюда, потом, наверное, будет <img src="../../images/close.svg" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button></div>
                        {/*<div className="close-but"><button onClick={this.closeNdaPopup3}><img src="../../images/close.svg" /></button></div>*/}
                        <h1 className="header-text-cl-kk">ПЕРЕХОД НА ТАРИФ «БИЗНЕС» <hr></hr> </h1>
                        <div className="center">
                            <p className="downblock-text-kk">Тариф «Бизнес». <a href="https://realty.viomitra.ru/tariffs#bisness" target="_blank">Подробности о тарифе.</a><br />Стоимость — 4 000 ₽</p>
                        </div>
                        <div className="center">
                            {/*<button className="log-in-cl-kk pay-card" onClick={this.openPay1Form}>Оплата картой</button>*/}
                            {/*<button className="sign-in-cl-kk" onClick={this.openPay2Form}>Оплата через платежную систему </button>*/}
                            <button className="log-in-cl-kk pay-card" style={{ width: '70%' }} onClick={this.openPay3Form}>Формирование платёжного поручения</button>
                        </div>
                    </div>}

                    {this.state.IsPaid && this.state.IsNdaPopupOpen4 && <div className="wrap-congrats">
                        <button className="closeBtn-log-in" type="button" onClick={this.closeGoodPay}>
                            {/* <img src="../../images/close.svg" /> */}
                            {/* временно вставила svg сюда, потом, наверное, будет импорт */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button>
                        <h2 className="login-header-log-in">ПОЗДРАВЛЯЕМ! <hr></hr> </h2>
                        <p className="text-good-r text-good-pay">Вам доступна категория «Закрытые продажи».</p>
                        <button className="log-in-cl-kk-log-in" onClick={this.openLoginForm}>Перейти к лоту</button>
                    </div>}

                    {!this.state.IsPaid && this.state.IsNdaPopupOpen4 && <div className="wrap-congrats" /* style={{ display: this.state.IsNdaPopupOpen4_1 ? 'flex' : 'none' }} */>
                        <button className="closeBtn-log-in" type="button" onClick={this.closeGoodPay_1}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button>
                        <h2 className="login-header-log-in">Поздравляем! <hr></hr> </h2>
                        <p className="text-good-r text-good-pay long-text-good-pay" >Активация тарифа &laquo;Бизнес&raquo; почти завершена. Вам осталось совершить платеж с&nbsp;помощью системы вашего банка, а&nbsp;нам&nbsp;&mdash; дождаться процесса зачисления средств.
                            Доступ к&nbsp;категории &laquo;Закрытые продажи&raquo; будет открыт в&nbsp;течение трех рабочих дней после поступления оплаты.</p>
                    </div>}

                    {/* ТЕКСТ ПРИ ОПЛАТЕ КАРТОЙ:
                        <h2 className="login-header-log-in">Активация тарифа «Бизнес» почти завершена.</h2>
                        <p className="text-good-r text-good-pay">Нашей платформе нужно немного времени, чтобы обработать поступление денежных средств при оплате картой.
                            Доступ к категории «Закрытые продажи» будет открыт в течение трех рабочих дней после поступления оплаты.</p> */}


                    {this.state.Step === 3 && (
                        <div id="paymentPopup" className="wrap-login100" style={{ display: this.state.IsPaymentPopupOpen ? 'block' : 'none' }}>
                            <form className="login100-form validate-form p-l-55 p-r-55 p-t-178">
                                <span className="login100-form-title">Оплата</span>
                                {/*<button className="closeBtn" type="button" onClick={() => this.setState({ ...this.state, IsPaymentPopupOpen: false })}><img src="../../images/close.svg" /></button>*/}
                                <div className="close-btn"><button onClick={() => this.setState({ ...this.state, IsPaymentPopupOpen: false })}>
                                    {/* <img src="../../images/close.svg" /> */}
                                    {/* временно вставила svg сюда, потом, наверное, будет <img src="../../images/close.svg" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                                </button></div>
                                <button className="submitBtn" type="button">Оплатить</button>
                            </form>
                        </div>
                    )}

                    {this.state.Step === 3 && (
                        <div>
                            <button onClick={this.navigateToLotCard}>Перейти на карточку лота</button>
                            <button onClick={this.sendOrderMessageToTelegram}>Запрос цен и предложений</button>
                        </div>
                    )}
                </div >}
            </React.Fragment>
        );
    }
}
export default SCCloseRowItem;