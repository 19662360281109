/* eslint-disable prettier/prettier */
import { DocumentTypes } from "../Common/types";
import Fetcher from "../Common/Fetch";

class AccountInfo  {
    constructor() { }

    Id: 0;
    Fio: "";
    IsAdminRole: false;
    Thumbprint: "";
    CurrentUserCanSign: false;
    ListDocuments: [{ Id: 0, DocumentType: DocumentTypes, IsSigned: false, DataToSign: string, Signature: string }];
    PaymentLots: [{ AccountId: 0, LotId: 0, StatusId: 0 }];
    Services: [{}];

    init(callback) {

        this.ListDocuments = [{}];
        this.Services = [{}];

        if (this.Id > 0)
            Fetcher('/ProfileApi/GetAccountInfo/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.PaymentLots = data.payments;

                    if (data.documentContract) {
                        this.ListDocuments.push(data.documentContract);
                    }
                    this.Services = data.services;

                    callback(true);
                })
                .catch((exception) => {
                    console.log('GetAccountInfo catch: ', exception);
                    callback(true);
                });
    }

}
export default new AccountInfo();