import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from "react";
import { useSignal, useSlot } from 'react-signal-slot'
import Fetcher from "../Common/Fetch";
import { TimeSearchType, TimeDescription } from "../Common/types"

interface IPropsType {
    selected: number
}

function FilterTime(props: any, ref) {

    // состояние для открытия/скрытия фильтра
    const [isOpen, setIsOpen] = useState(false);

    var timeTypes = [   TimeSearchType.None,
                        TimeSearchType.Nearest,
                        TimeSearchType.New,
                        TimeSearchType.Finished,
                        TimeSearchType.Trading,
                        TimeSearchType.Finishing10Mins,
                        TimeSearchType.AllowAcceptance,
                        TimeSearchType.AllowAcceptance10Mins
                     ];
    const signalSelectTime = useSignal();

    const selectedItem = (type) => {
        console.log('onClick', type);
        signalSelectTime('SelectTimeType', type);
    }

    return (
        <>
        <h5 className="sidebar-title" onClick={() => { setIsOpen(!isOpen) }}>По времени <img src="/main-images/categoryFilterArrow.svg"></img></h5>
        <div className="sidebar-item-box mb-4" style={{ display: isOpen ? 'flex' : 'none' }}>
            <ul className="sidebar-nav-items">
                {timeTypes && timeTypes.map(type => (
                    <li className={props.selected == type ? "active" : ""}><a href="#" onClick={(e) => { selectedItem(type) }} title={TimeDescription[type]}>{TimeDescription[type]}</a></li>
                ))}
                </ul>
            
            </div>
        </>
    );
}

export default FilterTime;