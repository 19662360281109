/* eslint-disable prettier/prettier */
import { bool } from "yup";

declare var cadesplugin: any;
declare var checkPlugin: any;
declare var canAsync: any;
declare var PrepareCertSelector: any;
declare var CADESCOMCryptoProviderAsync: any;
declare var CADESCOMCryptoProvider: any;
import React, { useState, useEffect } from "react";
import Fetcher from "../../Common/Fetch";
import { ShowDelayedNotification } from "../../Common/functions";
import moment from "moment";

interface IPropsType {
    certName: string,
    certDate: string,
    isLoginForm: boolean
}

enum AccountStatuses {
    Active = 1,
    WaitingConfirmation = 2,
    RegistrationDeclined = 3,
    Blocked = 4,
    New = 5,
    WaitingCertificateConfirmation = 6,
    AccessDenied = 7,
    InvalidCertificate = 8,
    NotAccepted = 9
}

const statusMap: { [key: number]: string } = {
    [AccountStatuses.New]: "Загружен и не проверяется",
    [AccountStatuses.WaitingConfirmation]: "Загружен и проверяется",
    [AccountStatuses.NotAccepted]: "Отклонён на проверке",
    [AccountStatuses.Active]: "Принят на проверку"
};

function Signature(props: IPropsType) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [selectedCert, setSelectedCert] = useState(null);
    const [certChanged, setCertChanged] = useState(false);
    const [certName, setCertName] = useState(props.certName);
    const [certDate, setCertDate] = useState(props.certDate);
    const [error, setError] = useState(null);
    const [certStatus, setСertStatus] = useState("");
    const [certStatusId, setCertStatusId] = useState(-1);

    if (isFirstLoad) {
        setIsFirstLoad(false)
    }


    const selectCert = () => {
        cadesplugin.then(function () {
            checkPlugin(function (isPluginWorking, version) {
                if (isPluginWorking) {
                    let signer = null;

                    if (canAsync()) {
                        signer = new CADESCOMCryptoProviderAsync({ showErrors: true, detached: false });

                    } else {
                        signer = new CADESCOMCryptoProvider({ showErrors: true, detached: false });
                    }

                    signer.selectCertificate(PrepareCertSelector, function (hash) {
                        signer.preferredThumbprint = hash;
                        signer.checkLocal(false, function (result) {
                            if (result) {
                                if (props.isLoginForm) {
                                    signer.getCertSelectorInfo(function (flag, cert) {
                                        if (flag) {
                                            Fetcher("/Account/LoginWithCertificate", {
                                                method: 'POST', headers: { 'Content-Type': 'application/json' },
                                                body: JSON.stringify({ "thumbPrint": signer.preferredThumbprint })
                                            }).then(res => {
                                                return res.json()
                                            }).then(res => {
                                                if (res.returnUrl) {
                                                    setError(null);
                                                    // setCertChanged(true);
                                                    window.location = res.returnUrl;
                                                } else {
                                                    setError('Некорректный сертификат');
                                                }
                                            })
                                        } else {
                                            setError('Некорректный сертификат');
                                        }
                                    });
                                } else {
                                    signer.getCertSelectorInfo(function (flag, cert) {
                                        console.log(`Flag для Signature ${flag}`);
                                        console.log(`Cert для Signature ${JSON.stringify(cert)}`);
                                        console.log(`rawData для Signature ${JSON.stringify({ "rawData": cert.Export })}`);
                                        if (flag) {


                                            Fetcher("/Certificate/ValidateCertificate", {
                                                method: 'POST', headers: { 'Content-Type': 'application/json' },
                                                body: JSON.stringify({ "rawData": cert.Export })
                                            }).then(res => {
                                                return res.json()
                                            })
                                                .then(res => {
                                                    console.log(`Результат валидации ${JSON.stringify(res)}`);
                                                    console.log(`Результат result для валидации ${JSON.stringify(res.result)}`)
                                                    if (res.result) {
                                                        setSelectedCert(cert);
                                                        setCertName(cert.SubjectName);
                                                        setCertDate(moment(cert.ValidToDate).format('DD.MM.YYYY HH:mm'));
                                                        setError(null);
                                                        console.log(cert);
                                                        setCertChanged(true);
                                                        saveCert();
                                                    } else {
                                                        setError(res.errorMessage);
                                                        console.log(error);
                                                    }
                                                })
                                        } else {
                                            setError('Некорректный сертификат');
                                            console.log(error);
                                        }
                                    });
                                }
                            } else {
                                setError('Некорректный сертификат');
                                console.log(error);
                            }
                        });
                    });
                }
            });
        });
    };


    const saveCert = () => {
        Fetcher("/ProfileApi/SaveCert", {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "Cert": selectedCert.Export })
        }).then(res => res.json())
            .then(res => {
                if (res.Success) {
                    setSelectedCert(null);
                    setCertChanged(false);
                    ShowDelayedNotification("Сертификат сохранен")
                }
            })
    };

    Fetcher("/ProfileApi/GetCertificateStatus", {
        method: 'GET', headers: { 'Content-Type': 'application/json' },
    })
        .then(res => res.json())
        .then(result => {
            console.log(`status and statusId ${result.Status}, ${result.StatusId}`);
            setСertStatus(result.Status);
            setCertStatusId(result.StatusId);
        }
        );

    return (
        <>
            {props.isLoginForm ?
                <div className='sertificateWrap'>
                    <div className="cont">
                        {!selectedCert && <button onClick={() => selectCert()}
                            className="sertificateInput"> {props.isLoginForm ? 'Вход по ЭЦП' : 'Выбрать'}  </button>}
                        {error && <p className="ErrorText">{error}</p>}
                    </div>
                </div>
                :
                <div className='sertificateWrap'>
                    <div className="h2_Cert">
                        <h4>Сертификат</h4>
                        {certStatusId !== Number(AccountStatuses.Active) && <p className="sertificateNull">{statusMap[certStatusId]}</p>}
                        {/*{certStatusId === Number(AccountStatuses.Active) && <p className="sertificateDate"> действителен до {certDate} </p>}*/}
                    </div>

                    <div className={"cont"}>
                        <div className="divwithbutsert">
                            <button onClick={() => selectCert()} className="sertificateInput">
                                {certStatusId == 0 ? 'Загрузить сертификат' : `Загружен ${certName}`}
                            </button>
                            <div className="certInfo">
                                {certStatusId == 0 ? (
                                    <p className="certNull">Не загружен</p>
                                ) : (
                                    <>
                                        <div className="certDescription">
                                            <span>{certDate}</span>
                                            <span>{certName}</span>
                                        </div>

                                        <div className="certPrompt">
                                            <p>После выбора ЭЦП перезагрузите страницу</p>
                                            <p>Для возможности совершать юридически значемые действия заполните данные, добавьте документы и отправьте администратору для подтверждения</p>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/*<p className="sertificateNull">{certStatusId == 0 ? 'Не загружен' : certStatus}</p>*/}

                            <p className="errors">{error}</p>
                        </div>

                        {/*{selectedCert && certChanged && <button onClick={() => saveCert()} className="sertificateInput"> Сохранить </button>}*/}
                    </div>
                </div>
            }


        </>
    );

}

export default Signature;