import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from "react";
import { useSignal, useSlot } from 'react-signal-slot'
import Fetcher from "../Common/Fetch";
import { TradeTypeSearch, TradeTypeDescription } from "../Common/types"

interface IPropsType {
    selected: number
}

function FilterTradeType(props: any, ref) {

    // состояние для открытия/скрытия фильтра
    const [isOpen, setIsOpen] = useState(false);

    var tradeTypes = [TradeTypeSearch.None, TradeTypeSearch.Open, TradeTypeSearch.Closed];
    const signalSelectTradeType = useSignal();

    const selectedItem = (type) => {
        console.log('onClick', type);
        signalSelectTradeType('SelectTradeType', type);
    }

    return (
        <>
        <h5 className="sidebar-title" onClick={() => { setIsOpen(!isOpen) }}>Тип торгов <img src="/main-images/categoryFilterArrow.svg"></img></h5>
        <div className="sidebar-item-box mb-4" style={{ display: isOpen ? 'flex' : 'none' }}>
            <ul className="sidebar-nav-items">
                {tradeTypes && tradeTypes.map(type => (
                    <li className={props.selected == type ? "active" : ""}><a href="#" onClick={(e) => { selectedItem(type) }} title={TradeTypeDescription[type]}>{TradeTypeDescription[type]}</a></li>
                ))}
                </ul>
            
            </div>
        </>
    );
}

export default FilterTradeType;