import React, { MouseEventHandler } from "react";

interface IPropsType {
    currentPage: number,
    totalCount: number,
    pageSize: number,
    toPage: (page: number) => () => void,
    additionalClass?: string
}

function paging(props: IPropsType) {
    let className = "app-pagination d-flext align-items-center";
    if (props.additionalClass) {
        className += " " + props.additionalClass;
    }

    const next = () => {
        //props.toPage(props.currentPage + 1);
        console.log('next');
        if (props.currentPage < (props.totalCount / props.pageSize))
            props.toPage(props.currentPage + 1);
    }

    const prev = () => {
        //props.toPage(props.currentPage - 1);
        console.log('prev');
        if (props.currentPage > 1)
            props.toPage(props.currentPage - 1);
    }

    return (
        <div className={className}>
            {props.currentPage > 1 && <button className="m-icon-arrow-angle-right next-page left-arrow" onClick={prev}></button>}
            <button className="btn current-page">{ props.currentPage }</button>
            <span className="page-of-pages">...{Math.ceil(props.totalCount / props.pageSize)}</span>
            {props.currentPage < (props.totalCount / props.pageSize) && <button className="m-icon-arrow-angle-right next-page" onClick={next}></button>}
        </div>


    );

}

export default paging;