import React, { useState, useEffect, useRef, useCallback } from "react";
import history from "../history";
import { useSignal, useSlot } from 'react-signal-slot'
import TreeCategories from "./TreeCategories"
import Fetcher from "../Common/Fetch";
import { fetcherPOSTJSON } from "../Common/Fetch";
import { AuctionTypeSearchType, TradeTypeSearch, TimeSearchType } from "../Common/types"
import Paging from "../Common/Paging/Paging";
import AgentHelper from '../CategoryLotsList/AgentHelper/AgentHelper';
import { CardLotModel } from "./types"
import CardLot from "./CardLot"
import CardLotCloseSales from "./CardLotCloseSales"
import FilterAuctionType from "./FilterAuctionType"
import FilterTradeType from "./FilterTradeType"
import FilterPrice from "./FilterPrice"
import FilterDate from "./FilterDate"
import FilterTime from "./FilterTime"
import FilterSearchLots from "./FilterSearchLots"
import AccountInfo from "../Profile/AccountInfo";
import SaleOrigPaintings from "../SaleOrigPaintings/SaleOrigPaintings";

// данная модель используется для передечи через неё данных контроллеру и хранимой процедуре
// необходимо cтрого поддерживать соответвтие её полей классу Mithra.Data.Models.Catalog.CatalogModel
// так как этот класс сериализуется в json и передаётся на сервер, где его принимают в качетсве модели данных Mithra.Data.Models.Catalog.CatalogModel
class FilterModel {
    CategoryId: number = 0;
    CurrentPage: number = 1;
    CountLotsOnPage: number = 10;
    AuctionType: AuctionTypeSearchType = AuctionTypeSearchType.None;
    SalesType: TradeTypeSearch = TradeTypeSearch.None;
    TimeSearch: TimeSearchType = TimeSearchType.None;
    StartDate: string;
    EndDate: string;
    StartPrice: number = 0;
    EndPrice: number = 0;
    TextSearch: string;
    dateInputLocale: object;
}

function Catalog(props: FilterModel, ref)
{
    // некоторый флаг необходимоый нам для единоразового вызова метода, получающего информацию о эцп пользователя
    const [isLoadingCatalog, setIsLoadingCatalog] = useState(false);

    // в этом свойстве мы храним все фильтры каталога
    // !!! Внимание, следить за состоянием этой переменной или изменять её в другом компоненте нельзя!
    // чтобы изменения которые вы хотите внести в фильтры попали в эту переменную используем сигналы и слоты
    // в ответ на сигнал у вашего компонента должен вызваться новый метод в данном компоненте и в этом методе вы можете изменить состояние этой переменной
    const [filters, setFilters] = useState<FilterModel>(props);

    const [lots, setLots] = useState<CardLotModel>([]);

    //Показ/скрытие фильтров на мобилке
    const [showFilters, setShowFilters] = useState<boolean>(true);

    // даное свойство содержит общее количество лотов удовлетворяющее заданным фильтрам, необходимо для пагинации по страницам
    const [totalLots, setTotalLots] = useState(0);

    useSlot('categoryChange', (id) => changeCategory(id));
    useSlot('SelectAuctionType', (type) => ChangeAuctionType(type)); // subscribe the signal of "signal"
    useSlot('SelectTradeType', (type) => ChangeTradeType(type));
    useSlot('SelectTimeType', (type) => ChangeTimeType(type));
    useSlot('ChangePrice', (value) => ChangePrice(value));
    useSlot('ChangeDate', (value) => ChangeDate(value));
    useSlot('SearchLotByText', (value) => SearchLotsByText(value));

    // маленький hook метод который отработает у нас всего доин раз для получения эцп пользователя
    useEffect(() => {
        console.log('hook on filters ', filters);
        if (!isLoadingCatalog) {
            setIsLoadingCatalog(true); 
            loadOrUpdateCatalog();
        }
    }, [filters]);

    const loadOrUpdateCatalog = () => {

        if (!isLoadingCatalog) {
            setIsLoadingCatalog(true); 

            var json = JSON.stringify(filters);
            console.log('filters = ', json);
            updateUrl();
            fetcherPOSTJSON("/Category/GetListLots", json, function (result) {
                if (result.Success) {
                    console.log('success');
                    console.log(result);
                    setTotalLots(result.TotalRows);
                    setLots(result.lots);
                    setIsLoadingCatalog(false);
                    console.log(filters);
                }
                else {
                    console.log('error!');
                    setIsLoadingCatalog(false);
                }
            });
        }
    }

    const updateUrl = () => {
        console.log('формирование url из filters = ', filters);
        const url = "/Category/Details?";
        const queryString = `${url}${(objectToParams(filters))}`;
        console.log(queryString);

        history.push(queryString);
    }

    const objectToParams = (object) => {
        if (object && Object.keys(object).length > 0) {
            const resultArr = [];
            Object.keys(object).map((key) => {
                const keys = Object.keys(object[key]).join(",");
                resultArr.push(`${key}=${object[key]}`);
            });
            return resultArr.join("&");
        }
    };

    // данный метод вызывается при изменении страницы пагинации каталога,
    // т.е. пользователь кликнул на другой номер страницы в компоненте Paging
    const changePage = (numberPage) => {
        console.log('changePage', numberPage);
        if (filters.CurrentPage != numberPage) {
            setFilters({ ... filters, CurrentPage: numberPage });
        }
    }

    // данный метод вызывается при выборе категории
    // т.е. пользователь кликнул на другую категория в древовидном списке разделов каталога
    const changeCategory = (id) => {
        console.log('changeCategory new value=' + id + ' old value =' + filters.CategoryId);
        if (filters.CategoryId != id) {
            setFilters({ ...filters, CategoryId: id, CurrentPage: 1 });
        }
    }

    // данный метод вызывается при выборе типа аукционов
    // т.е. пользователь кликнул на другой тип аукционов в панели фитров слева
    const ChangeAuctionType = (type) => {
        console.log('ChangeAuctionType', type);
        if (filters.AuctionType != type) {
            setFilters({ ...filters, AuctionType: type, CurrentPage: 1 });
        }
    }

    // данный метод вызывается при выборе типа продаж (лотов)
    // т.е. пользователь кликнул на другой тип лотов в панели фитров слева
    const ChangeTradeType = (type) => {
        console.log('ChangeTradeType', type);
        if (filters.SalesType != type) {
            setFilters({ ...filters, SalesType: type, CurrentPage: 1 });
        }
    }

    // данный метод вызывается при выборе фильтрации по времени
    // т.е. пользователь кликнул на другой тип в списке "По времени"
    const ChangeTimeType = (type) => {
        console.log('ChangeTimeType', type);
        if (filters.TimeSearch != type) {
            setFilters({ ...filters, TimeSearch: type, CurrentPage: 1 });
        }
    }

    // данный метод предназначен для фильтра Цена. Он вызвается в ответ на сигнла от компонент FilterPrice
    // т.е. пользователь хочет отфильтровать лоты по цене на боковой панели слева
    const ChangePrice = (value) => {
        console.log('ChangePrice', value);
        if (filters.StartPrice != value.StartPrice || filters.EndPrice != value.EndPrice) {
            setFilters({ ...filters, StartPrice: value.StartPrice, EndPrice: value.EndPrice, CurrentPage: 1 });
        }      
    }

    // данный метод предназначен для фильтра Дата торгов. Он вызвается в ответ на сигнла от компонент FilterDate
    // т.е. пользователь хочет отфильтровать лоты по дате начала или окончания торгов на боковой панели слева
    const ChangeDate = (value) => {
        console.log('ChangeDate', value);
        if (filters.StartDate != value.StartDate || filters.EndDate != value.EndDate) {
            setFilters({ ...filters, StartDate: value.StartDate, EndDate: value.EndDate, CurrentPage: 1 });
        }
    }

    // данный метод предназначен для фильтра - Поиск по названию/описанию лота
    // Он вызывается в ответ на сигнал от компонента FilterSearchLots
    const SearchLotsByText = (value) => {
        console.log('SearchLotsByText', value);
        if (filters.TextSearch != value) {
            setFilters({ ...filters, TextSearch: value, CurrentPage: 1 });
        }
    }

    // данный метод вызывается при клике на сердечко, чтобы добавить лот в избранное
    const onIsFavoriteToggle = () => {

    }


    //Свернуть панельку фильтров на мобилке
    useEffect(() => {
        const media = window.matchMedia('(max-width: 992px)');

        if (media.matches) {
            setShowFilters(false);
        }

        const changeShowFiltersState = () => {
            setShowFilters(false);
        }

        media.addEventListener('change', changeShowFiltersState);

        return () => {
            media.removeEventListener('change', changeShowFiltersState);
        };
    }, [])

    return (
        <React.Fragment>
            <div className="loading" id="divLoading" style={{ display: isLoadingCatalog ? "block" : "none"}}></div>
            <div className="row gutter-row-10">
                <div className="col-auto gutter-col-10 sidebar-col" id="catalogFilterMain">
                    <FilterSearchLots text={filters.TextSearch} />
                    <span className="extendedSearch" onClick={() => { setShowFilters(!showFilters) }}>Расширенный поиск
                        <svg style={{ rotate: showFilters ? '180deg' : '' }} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                            <path d="M4.80794 0.230466L1.09346 4.68785C0.659238 5.20891 1.02976 6 1.70803 6L8.29197 6C8.97024 6 9.34076 5.20892 8.90654 4.68785L5.19206 0.230466C5.09211 0.110528 4.90789 0.110528 4.80794 0.230466Z" fill="#690097" />
                        </svg>
                    </span>


                    <div className="sidebar-inner" id="mainFilterCatalog" style={{ display: showFilters ? 'block' : 'none' }}>
                        <AgentHelper userName={AccountInfo.Fio} />
                        <div className="sidebar-open-wrapper">
                            <TreeCategories CategoryId={filters.CategoryId} />
                        </div>
                        <div className="sidebar-open-wrapper">
                            <FilterAuctionType selected={filters.AuctionType} />
                        </div>
                        <div className="sidebar-open-wrapper">
                            <FilterTradeType selected={filters.SalesType} />
                        </div>
                        <div className="sidebar-open-wrapper priceFilter">
                            <FilterPrice StartPrice={filters.StartPrice} EndPrice={filters.EndPrice} />
                        </div>
                        <div className="sidebar-open-wrapper timeFilter">
                            <FilterTime selected={filters.TimeSearch} />
                        </div>
                        <div className="sidebar-open-wrapper dateFilter">
                            <FilterDate StartDate={filters.StartDate} EndDate={filters.EndDate} CalendarLocale={filters.dateInputLocale} />
                        </div>
                    </div>
                    <div id="preOrderContainer">
                        <SaleOrigPaintings />
                    </div>
                </div>
                
                <div className="col gutter-col-10">
                    {lots.length > 0 && <div className="col-auto new-c-auto">
                        <Paging
                            currentPage={filters.CurrentPage}
                            totalCount={totalLots}
                            pageSize={filters.CountLotsOnPage}
                            toPage={changePage}
                            additionalClass={null}
                        />
                    </div>}
                    {lots.length > 0 ? lots.map(lot => (
                        <div key={lot.Id} className="sub-category-card">
                            {lot.IsClosedTrade ?
                                <CardLotCloseSales lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                                :
                                <CardLot lot={lot} onIsFavoriteToggle={onIsFavoriteToggle} />
                            }
                        </div>
                            
                    )) : !isLoadingCatalog && <p>К сожалению, по вашему запросу ничего не найдено</p>}
                    {lots.length > 0 && <div className="col-auto new-c-auto">
                        <Paging
                            currentPage={filters.CurrentPage}
                            totalCount={totalLots}
                            pageSize={filters.CountLotsOnPage}
                            toPage={changePage}
                            additionalClass={null}
                        />
                    </div>}
                </div>

                <div id="preOrderContainerMobile">
                    <SaleOrigPaintings />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Catalog;